import { defaultDataIdFromObject, FieldPolicy, InMemoryCache, Reference } from '@apollo/client';
import { ToReferenceFunction } from '@apollo/client/cache/core/types/common';
import { relayStylePagination } from '@apollo/client/utilities';
import { MaterialType, possibleTypes, StrictTypedTypePolicies } from '@mnd-frontend/graphql-schema';
import { uniqBy } from 'lodash-es';
import { isApolloReadFunctionBlocked } from './apolloReadBlocker';

const connectionPolicy = (keyArgs: FieldPolicy['keyArgs'] = false) => {
  const fieldPolicy: FieldPolicy = {
    keyArgs,
    merge: (
      existing: { nodes: unknown[] } | undefined | null,
      incoming: { nodes: unknown[] },
      { args },
    ) => {
      const correctOrder = args?.last
        ? (arg: unknown[]) => [...arg].reverse()
        : (arg: unknown[]) => arg;
      if (!existing || !(args?.before || args?.after)) {
        return { ...(existing ?? {}), ...incoming, nodes: correctOrder(incoming.nodes) };
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { nodes: incomingNodes, ...incomingRest } = incoming;
      const merged = { ...existing, ...incomingRest };
      merged.nodes = [...existing.nodes, ...correctOrder(incoming.nodes)];
      return merged;
    },
  };

  return fieldPolicy;
};

const quotationsPagination = relayStylePagination(['skipHidden']);
const beforeRead = quotationsPagination.read;
/**
 * This is done to fix a bug where loading preview screen would populate cache with 5,
 * and then going into slideshow would ask for 100, but the default logic would only return 5.
 */
quotationsPagination.read = (existing, opts) => {
  if (!existing || !beforeRead) return;

  const askingCount = opts.args?.first;
  const startFromStratch = !opts.args?.after;
  const hasCount = existing.edges.length;
  const totalCount = (existing as any).totalCount || 0;
  if (askingCount > hasCount && startFromStratch && hasCount < totalCount) {
    return;
  }

  return beforeRead(existing, opts);
};

const pdfSlideshows = {
  read(
    _: unknown,
    { args, toReference }: { args: Record<string, any> | null; toReference: ToReferenceFunction },
  ) {
    return args?.ids.map((id: string) =>
      toReference({
        __typename: 'PdfSlideshow',
        id,
      }),
    );
  },
};

const sections2 = {
  read(
    sectionsData: any,
    {
      args,
      toReference,
    }: {
      args: Record<string, any> | null;
      toReference: ToReferenceFunction;
    },
  ) {
    const excludedIds = new Set(args?.excludeIds ?? []);
    if (args?.ids) {
      return args.ids
        .filter((id: string) => !excludedIds.has(id))
        .map((id: string) => toReference(reportSectionId(id)));
    } else if (excludedIds.size > 0) {
      return undefined;
    }

    return sectionsData;
  },
  keyArgs: ['ids', 'excludedIds'],
  merge: false,
};

const typePolicies: StrictTypedTypePolicies = {
  Ideation: {
    fields: {
      storyIdeas: {
        merge: false,
      },
      likedStoryIdeas: {
        merge: false,
      },
    },
  },

  ContactImport: {
    fields: {
      duplicateEmailsList: connectionPolicy(['id']),
      invalidEmailsList: connectionPolicy(['id']),
      filterFailedEmailList: connectionPolicy(['id']),
    },
  },
  MonitorHitList: {
    fields: {
      hits2: {
        merge(existing, incoming, { variables }) {
          if (!variables?.pageParams) return incoming;
          return existing ? [...existing, ...incoming] : incoming;
        },
      },
    },
  },
  PendingNotices: {
    fields: {
      coverageReportNotices: {
        merge: false,
      },
    },
  },
  LinkedinPost: {
    fields: {
      images2: {
        merge: false,
      },
    },
  },
  SourceFeatures: {
    fields: {
      coverageReports: {
        merge: false,
      },
    },
  },
  SocialMediaPosts: {
    fields: {
      linkedinPostsByDate2: {
        keyArgs: ['channelId'],
        merge(existing: Reference[] | undefined, incoming: Reference[], { readField }) {
          if (existing) {
            const existingIds = existing.map(ref => readField('id', ref));
            const uniqueIncoming = incoming.filter(
              incomingRef => !existingIds.includes(readField('id', incomingRef)),
            );
            return [...existing, ...uniqueIncoming];
          }
          return incoming;
        },
      },
      scheduledLinkedinPosts: connectionPolicy(['channelId']),
      sentLinkedinPosts: connectionPolicy(['channelId']),
    },
  },
  ContactPerson2: {
    fields: {
      links: {
        merge: false,
      },
    },
  },
  Query: {
    fields: {
      materials: {
        read(_, { args, toReference }) {
          let tuples = args?.idTuples;
          tuples = Array.isArray(tuples) ? tuples : [tuples];
          return tuples.map((idTuple: { id: string; type: MaterialType }) =>
            toReference({
              __typename: idTuple.type,
              id: idTuple.id,
            }),
          );
        },
      },
    },
  },
  PressroomFeatures: {
    fields: {
      audienceBuilder2: {
        merge: false,
      },
    },
  },
  Pressroom: {
    fields: {
      newsroomFeeds: {
        merge: false,
      },
      reports2: {
        merge: false,
      },
      materialList2: connectionPolicy(['types', 'status', 'time', 'sort', 'page', 'query']),
      images: connectionPolicy(['status', 'query', 'ids']),
      contactPeopleList: {
        merge: false,
      },
      networkContactLists: {
        read(_, { args, toReference }) {
          if (isApolloReadFunctionBlocked('Pressroom.networkContactLists')) return;

          return args?.ids.map((id: string) =>
            toReference({
              __typename: 'NetworkContactList',
              id,
            }),
          );
        },
      },
      networkContacts2: {
        read(_, { args, toReference }) {
          return args?.ids.map((id: string) =>
            toReference({
              __typename: 'NetworkContact',
              id,
            }),
          );
        },
      },
      pressContactList: connectionPolicy(['published', 'query']),
      networkContactList: connectionPolicy(['search', 'sort']),
      quotations: connectionPolicy(['time']),
      channels: {
        merge: false,
      },
      features: {
        merge(existing, incoming) {
          return {
            ...existing,
            ...incoming,
          };
        },
      },
    },
  },
  FindJournalist: {
    fields: {
      search: connectionPolicy(['topicIds', 'outletIds', 'regionIds']),
    },
  },
  Monitor: {
    fields: {
      newsAlerts: {
        merge: false,
      },
      hitList: {
        keyArgs: ['freetextSearch', 'groupIdentical', 'locations', 'searchParams'],
      },
      searchProfiles: {
        merge: false,
      },
      groups: {
        merge: false,
      },
    },
  },
  MonitorProfileGroup: {
    fields: {
      profiles: {
        merge: false,
      },
      profiles2: {
        merge: false,
      },
    },
  },
  Video: {
    fields: {
      contactPeople2: {
        merge: false,
      },
    },
  },
  ContactPersonList: {
    fields: {
      contactPeople2: {
        merge: false,
      },
    },
  },
  Pressrelease: {
    fields: {
      relatedMaterial: {
        merge: false,
      },
      links: {
        merge: false,
      },
      contactPeople2: {
        merge: false,
      },
      tags: {
        merge: false,
      },
    },
  },
  ReportCoverageInterfaceConnection: {
    fields: {
      edges: {
        merge(existing, incoming, { readField }) {
          if (existing)
            return uniqBy([...existing, ...incoming], (ref: Reference) =>
              readField('id', readField('node', ref)),
            );
          return incoming;
        },
        read: (existing: Reference[] | undefined | null, { readField }) => {
          if (!existing) return;
          const sortedReferences: Reference[] = [];
          existing
            .map(ref => readField('sortIndex', readField('node', ref)))
            .map((sortIndex, i) => (sortIndex === undefined ? i : Number(sortIndex)))
            .forEach((sortIndex, index) => {
              sortedReferences[sortIndex] = existing[index];
            });
          return sortedReferences;
        },
      },
    },
  },
  ReportSection: {
    fields: {
      quotations: quotationsPagination,
    },
  },
  ReportQuotationGridSection: {
    fields: {
      quotations: quotationsPagination,
    },
  },
  ReportQuotationFullWidthSection: {
    fields: {
      quotations: quotationsPagination,
    },
  },
  ReportFeaturedSection: {
    fields: {
      quotationsList: {
        merge: false,
      },
    },
  },
  BrandReport: {
    fields: {
      pdfSlideshows,
      sections2,
    },
  },
  CoverageReport: {
    fields: {
      pdfSlideshows,
      sections2,
      importJobs: {
        merge: false,
      },
    },
  },
  News: {
    fields: {
      relatedMaterial: {
        merge: false,
      },
      links: {
        merge: false,
      },
      contactPeople2: {
        merge: false,
      },
      tags: {
        merge: false,
      },
    },
  },
  Source: {
    fields: {
      users: {
        merge: false,
      },
      invitations: {
        merge: false,
      },
    },
  },
  Tag: {
    fields: {
      materials: {
        merge: false,
      },
    },
  },
  TagHits: {
    fields: {
      hits: {
        merge: false,
      },
    },
  },
  BlogPost: {
    fields: {
      relatedMaterial: {
        merge: false,
      },
      links: {
        merge: false,
      },
      contactPeople2: {
        merge: false,
      },
      tags: {
        merge: false,
      },
    },
  },
  EmailSettings: {
    fields: {
      general: {
        merge: false,
      },
      design: {
        merge: false,
      },
    },
  },
  Material: {
    fields: {
      relatedMaterial: {
        merge: false,
      },
      regions: {
        merge: false,
      },
      subjects: {
        merge: false,
      },
    },
  },
  MaterialRecipients: {
    fields: {
      contacts: connectionPolicy(['sort', 'excludeIfMndReach', 'filter']),
      subscribers: connectionPolicy(['sort']),
      followers: connectionPolicy(['sort']),
    },
  },
  MaterialReport: {
    fields: {
      topMaterialReferrers: connectionPolicy(),
    },
  },
  NewsroomReport: {
    fields: {
      topReferrers: connectionPolicy(['time']),
      topStories: connectionPolicy(['time', 'limit']),
    },
  },
  NewsroomSettings: {
    fields: {
      regions2: {
        merge: false,
      },
      subjects2: {
        merge: false,
      },
    },
  },
  HostedNewsroomSettings: {
    merge: false,
  },
  HostedNewsroomStyle: {
    merge: false,
    fields: {
      availableFonts: connectionPolicy(['query']),
    },
  },
  NetworkContact: {
    fields: {
      networkContactLists: {
        merge: false,
      },
    },
  },
  TwitterFeed: {
    fields: {
      latestTweets: connectionPolicy(),
    },
  },
  AudienceBuilderContact: {
    fields: {
      lists: {
        merge: false,
      },
      articles: connectionPolicy(),
    },
  },
  StoryMaterial: {
    fields: {
      quotations2: connectionPolicy(),
    },
  },
  NewsroomStyle: {
    merge: true,
  },
};

export const reportSectionId = (id: string) => `ReportSectionInterface:${id}`;

export const getCache = () =>
  new InMemoryCache({
    possibleTypes: possibleTypes.possibleTypes,
    typePolicies,
    dataIdFromObject: object => {
      if (
        object.__typename &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        possibleTypes.possibleTypes.ReportSectionInterface.includes(object.__typename as any)
      ) {
        return (object.id as string | undefined)
          ? reportSectionId(object.id as string)
          : defaultDataIdFromObject(object);
      } else {
        return defaultDataIdFromObject(object);
      }
    },
  });
