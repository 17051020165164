export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AudienceBuilderGeographyId: { input: string; output: string; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  Email: { input: string; output: string; }
  JSON: { input: { [key: string]: any }; output: { [key: string]: any }; }
  Time: { input: string; output: string; }
};

export enum AccessLevel {
  /** User has read-only access. */
  Read = 'READ',
  /** User has read and write access. */
  ReadWrite = 'READ_WRITE'
}

/** Autogenerated input type of ActivateSimpleSearchProfiles */
export type ActivateSimpleSearchProfilesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  simpleSearchProfileIds: Array<Scalars['ID']['input']>;
  sourceSelection: SimpleSearchProfileSourceSelection;
};

/** Autogenerated input type of AddContactList */
export type AddContactListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactListId: Scalars['ID']['input'];
  journalists: Array<AudienceReachAddContactListInput>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of AddHitToClipboard */
export type AddHitToClipboardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clipboardIds: Array<Scalars['ID']['input']>;
  hitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of AnalyzeCreateExport */
export type AnalyzeCreateExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  /** When not set the export is for all materials in newsroom */
  materialId?: InputMaybe<Scalars['ID']['input']>;
  materialType?: InputMaybe<MaterialType>;
  period: DateTimeRangeInput;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of AudienceBuilderAdvancedSearchCreate */
export type AudienceBuilderAdvancedSearchCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pressroomId: Scalars['ID']['input'];
  searchParams: SearchParamsInput;
};

/** Autogenerated input type of AudienceBuilderAdvancedSearchDelete */
export type AudienceBuilderAdvancedSearchDeleteInput = {
  advancedSearchId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of AudienceBuilderContactList */
export type AudienceBuilderContactListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactListId: Scalars['ID']['input'];
  contacts: Array<ContactImportAttributes>;
  pressroomId: Scalars['ID']['input'];
};

/** Keys allowed in either keyword or parametric AgilityPr search. */
export type AudienceBuilderContactQuery = {
  /** For list of supported values, see AudienceBuilder's 'coverageFocuses` field. Expects `id` to be passed, e.g. NATIONAL. Multiple values allowed. */
  coverageFocuses?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Ignored contacts email */
  ignoredEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  /** For list of supported values, see AudienceBuilder's `jobRoles` field. Expects `id` to be passed, e.g. 14. Multiple values allowed. */
  jobRoles?: InputMaybe<Array<Scalars['ID']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  /** For list of supported values, see AudienceBuilder's `languageCodes` field. Expects `id` to be passed, e.g. en, fr. Multiple values allowed. */
  languageCodes?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** For list of supported values, see AudienceBuilder's `locations` field. Expects `#id|#type` to be passed, e.g. `3011|CITY`. */
  locations?: InputMaybe<Array<Scalars['AudienceBuilderGeographyId']['input']>>;
  /** For list of supported values, see AudienceBuilder's `mediaTypes` field. Expects `id` to be passed, e.g. PRINT. Multiple values allowed. */
  mediaTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<AudienceBuilderSortDirection>;
  /** Search term matching a contact outlet. */
  outletUuids?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  /** For list of supported values, see AudienceBuilder's `productTypes` field. Expects `id` to be passed, e.g. PODCAST. Multiple values allowed. */
  productTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  sortBy?: InputMaybe<AudienceBuilderSortField>;
  /** For list of supported values, see AudienceBuilder's `subjects` field. Expects `id` to be passed, e.g. 9343. Multiple values allowed. */
  subjects?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** For list of supported values, see AudienceBuilder's `targetAudiences` field.Expects `id` to be passed, e.g. HIGH_MANAGEMENT. Multiple values allowed. */
  targetAudiences?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated input type of AudienceBuilderQuery */
export type AudienceBuilderQueryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactListId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
  query: AudienceBuilderContactQuery;
};

export enum AudienceBuilderRegionType {
  City = 'CITY',
  Continent = 'CONTINENT',
  Country = 'COUNTRY',
  County = 'COUNTY',
  Subnational = 'SUBNATIONAL'
}

export enum AudienceBuilderSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum AudienceBuilderSortField {
  ContactName = 'CONTACT_NAME',
  FirstName = 'FIRST_NAME',
  JobTitle = 'JOB_TITLE',
  LastName = 'LAST_NAME',
  OutletName = 'OUTLET_NAME',
  PotentialAudience = 'POTENTIAL_AUDIENCE',
  Relevance = 'RELEVANCE'
}

export type AudienceReachAddContactListInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum AvatarUrl {
  Default = 'default',
  Original = 'original'
}

/** Autogenerated input type of BlogPostUpdate */
export type BlogPostUpdateInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  generatedByAiWriter?: InputMaybe<Scalars['Boolean']['input']>;
  generatedByAiWriterTemplate?: InputMaybe<GeneratedByAiWriterTemplate>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageCaption?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  resourceImageId?: InputMaybe<Scalars['ID']['input']>;
  showAiDisclaimerText?: InputMaybe<Scalars['Boolean']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

export enum BrandReportGroupStatisticsBy {
  Month = 'MONTH',
  Quarter = 'QUARTER'
}

export enum BrandReportState2 {
  CreatingInsights = 'CREATING_INSIGHTS',
  CreatingSentimentAnalysis = 'CREATING_SENTIMENT_ANALYSIS',
  Done = 'DONE',
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Regenerating = 'REGENERATING',
  RejectedTooFewHits = 'REJECTED_TOO_FEW_HITS'
}

export enum BrandReportTimespan {
  LastHalfYear = 'LAST_HALF_YEAR',
  LastMonth = 'LAST_MONTH',
  LastQuarter = 'LAST_QUARTER',
  LastYear = 'LAST_YEAR',
  Range = 'RANGE'
}

/** Autogenerated input type of ChannelAdd */
export type ChannelAddInput = {
  channelType: ChannelType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  facebookPageId?: InputMaybe<Scalars['ID']['input']>;
  linkedinCompanyId?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of ChannelDisconnect */
export type ChannelDisconnectInput = {
  channelId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

export type ChannelIdMessageTuple = {
  id: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};

export enum ChannelMaterialStatusState {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

/** Autogenerated input type of ChannelReauthenticate */
export type ChannelReauthenticateInput = {
  channelId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  facebookPageId?: InputMaybe<Scalars['ID']['input']>;
  linkedinCompanyId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ChannelType {
  Facebook = 'Facebook',
  Linkedin = 'Linkedin',
  Twitter = 'Twitter',
  YouTube = 'YouTube'
}

/** Autogenerated input type of ChannelUpdate */
export type ChannelUpdateInput = {
  channelId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  metaKey?: InputMaybe<Scalars['String']['input']>;
  publishByDefault?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input to send a clipboard email. */
export type ClipboardEmailInput = {
  /** Defines the language to be used for the email */
  locale: Scalars['String']['input'];
  recipients: Array<Scalars['String']['input']>;
  /** Defines the time offset that should be used in email. */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a clipboard input. */
export type ClipboardInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  hitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sortedBy?: InputMaybe<ClipboardSortedByField>;
  title: Scalars['String']['input'];
};

export enum ClipboardSortedByField {
  Author = 'author',
  PublishedAt = 'published_at',
  Reach = 'reach',
  Title = 'title'
}

/** All the supported options for our cloud provider (Cloudinary) */
export type CloudinaryOptionsAttributes = {
  /** One of the supported transformations */
  transformation?: InputMaybe<CloudinaryTransformations>;
};

export type CloudinaryResponse = {
  bytes?: InputMaybe<Scalars['Int']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  publicId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export enum CloudinaryTransformations {
  ContactPerson = 'CONTACT_PERSON',
  Default = 'DEFAULT',
  Default_746 = 'DEFAULT_746',
  Default_746_16x9 = 'DEFAULT_746_16x9',
  ImageBankThumbnail = 'IMAGE_BANK_THUMBNAIL',
  Limit_1000 = 'LIMIT_1000',
  /** Use to display a downscaled preview image of a material in a customers newsroom */
  MaterialHeaderPreview = 'MATERIAL_HEADER_PREVIEW',
  SquareSmall = 'SQUARE_SMALL',
  ThumbnailSmall = 'THUMBNAIL_SMALL'
}

export enum CnameDnsSetup {
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Valid = 'VALID',
  ValidNoSsl = 'VALID_NO_SSL'
}

export enum CommercialProduct {
  SfwSub_100000ExtraMentionRetainerMentionGaRX = 'SFW_SUB_100000_EXTRA_MENTION_RETAINER_MENTION_GA_R_X',
  SfwSubAdditionalMediaContactsSubscriptionMndGaRX = 'SFW_SUB_ADDITIONAL_MEDIA_CONTACTS_SUBSCRIPTION_MND_GA_R_X',
  SfwSubAnalyzeMndGaRX = 'SFW_SUB_ANALYZE_MND_GA_R_X',
  SfwSubAudienceBuilder_2_0MndGaRX = 'SFW_SUB_AUDIENCE_BUILDER_2_0_MND_GA_R_X',
  SfwSubAudienceBuilderAdditionalUserPartnerGaRX = 'SFW_SUB_AUDIENCE_BUILDER_ADDITIONAL_USER_PARTNER_GA_R_X',
  SfwSubAudienceBuilderAsiaPartnerGaRX = 'SFW_SUB_AUDIENCE_BUILDER_ASIA_PARTNER_GA_R_X',
  SfwSubAudienceBuilderAustriaPartnerDeRX = 'SFW_SUB_AUDIENCE_BUILDER_AUSTRIA_PARTNER_DE_R_X',
  SfwSubAudienceBuilderDachPartnerGaRX = 'SFW_SUB_AUDIENCE_BUILDER_DACH_PARTNER_GA_R_X',
  SfwSubAudienceBuilderEuropePartnerGaRX = 'SFW_SUB_AUDIENCE_BUILDER_EUROPE_PARTNER_GA_R_X',
  SfwSubAudienceBuilderGermanyPartnerGaRX = 'SFW_SUB_AUDIENCE_BUILDER_GERMANY_PARTNER_GA_R_X',
  SfwSubAudienceBuilderGlobalPartnerGaRX = 'SFW_SUB_AUDIENCE_BUILDER_GLOBAL_PARTNER_GA_R_X',
  SfwSubAudienceBuilderSingleCountryPartnerGaRX = 'SFW_SUB_AUDIENCE_BUILDER_SINGLE_COUNTRY_PARTNER_GA_R_X',
  SfwSubAudienceBuilderSwitzerlandPartnerDeRX = 'SFW_SUB_AUDIENCE_BUILDER_SWITZERLAND_PARTNER_DE_R_X',
  SfwSubBasicPlusCoreMndGaRX = 'SFW_SUB_BASIC_PLUS_CORE_MND_GA_R_X',
  SfwSubCoverageReportMndGaRX = 'SFW_SUB_COVERAGE_REPORT_MND_GA_R_X',
  SfwSubEnterpriseCoreMndGaRX = 'SFW_SUB_ENTERPRISE_CORE_MND_GA_R_X',
  SfwSubExtraEmailCredits_5KMndGaRX = 'SFW_SUB_EXTRA_EMAIL_CREDITS_5K_MND_GA_R_X',
  SfwSubExtraEmailCreditsMndGaRX = 'SFW_SUB_EXTRA_EMAIL_CREDITS_MND_GA_R_X',
  SfwSubExtraSearchProfiles_5MndGaRX = 'SFW_SUB_EXTRA_SEARCH_PROFILES_5_MND_GA_R_X',
  SfwSubExtraSearchProfilesUnlimitedMndGaRX = 'SFW_SUB_EXTRA_SEARCH_PROFILES_UNLIMITED_MND_GA_R_X',
  SfwSubGoCoreMndGaRX = 'SFW_SUB_GO_CORE_MND_GA_R_X',
  SfwSubHostedNewsroomMndGaRX = 'SFW_SUB_HOSTED_NEWSROOM_MND_GA_R_X',
  SfwSubMentionCompanyPlanMentionGaRX = 'SFW_SUB_MENTION_COMPANY_PLAN_MENTION_GA_R_X',
  SfwSubMentionEnterpriseMentionGaRX = 'SFW_SUB_MENTION_ENTERPRISE_MENTION_GA_R_X',
  SfwSubMentionExtraAlertMentionGaRX = 'SFW_SUB_MENTION_EXTRA_ALERT_MENTION_GA_R_X',
  SfwSubMentionExtraQuotaMentionGaRX = 'SFW_SUB_MENTION_EXTRA_QUOTA_MENTION_GA_R_X',
  SfwSubMentionHistoricDataMentionGaRX = 'SFW_SUB_MENTION_HISTORIC_DATA_MENTION_GA_R_X',
  SfwSubMentionStarterDachMentionDeRX = 'SFW_SUB_MENTION_STARTER_DACH_MENTION_DE_R_X',
  SfwSubMentionStarterMentionGaRX = 'SFW_SUB_MENTION_STARTER_MENTION_GA_R_X',
  SfwSubMentionUnlimitedSomeAccountsMentionGaRX = 'SFW_SUB_MENTION_UNLIMITED_SOME_ACCOUNTS_MENTION_GA_R_X',
  SfwSubMentionUnlimitedUsersMentionGaRX = 'SFW_SUB_MENTION_UNLIMITED_USERS_MENTION_GA_R_X',
  SfwSubMonitorAllLanguagesMndGaRX = 'SFW_SUB_MONITOR_ALL_LANGUAGES_MND_GA_R_X',
  SfwSubMonitorDagbladetPartnerNoRX = 'SFW_SUB_MONITOR_DAGBLADET_PARTNER_NO_R_X',
  SfwSubMonitorDagensNRingslivBetaltInnholdPartnerNoRX = 'SFW_SUB_MONITOR_DAGENS_N_RINGSLIV_BETALT_INNHOLD_PARTNER_NO_R_X',
  SfwSubMonitorLevel_1MndGaRX = 'SFW_SUB_MONITOR_LEVEL_1_MND_GA_R_X',
  SfwSubMonitorLevel_2MndGaRX = 'SFW_SUB_MONITOR_LEVEL_2_MND_GA_R_X',
  SfwSubMonitorMagazinesFagpressenPartnerNoRX = 'SFW_SUB_MONITOR_MAGAZINES_FAGPRESSEN_PARTNER_NO_R_X',
  SfwSubMonitorMndGaRX = 'SFW_SUB_MONITOR_MND_GA_R_X',
  SfwSubMonitorPaywallsMndGaRX = 'SFW_SUB_MONITOR_PAYWALLS_MND_GA_R_X',
  SfwSubMonitorPrintmediaAmediaPartnerNoRX = 'SFW_SUB_MONITOR_PRINTMEDIA_AMEDIA_PARTNER_NO_R_X',
  SfwSubMonitorPrintmediaHegnarPartnerNoRX = 'SFW_SUB_MONITOR_PRINTMEDIA_HEGNAR_PARTNER_NO_R_X',
  SfwSubMonitorPrintmediaKlassekampenAndOthersPartnerNoRX = 'SFW_SUB_MONITOR_PRINTMEDIA_KLASSEKAMPEN_AND_OTHERS_PARTNER_NO_R_X',
  SfwSubMonitorPrintmediaMagazinesMblPartnerNoRX = 'SFW_SUB_MONITOR_PRINTMEDIA_MAGAZINES_MBL_PARTNER_NO_R_X',
  SfwSubMonitorPrintmediaMndPartnerNoRX = 'SFW_SUB_MONITOR_PRINTMEDIA_MND_PARTNER_NO_R_X',
  SfwSubMonitorPrintmediaPolarisKlassekampenMndGaRX = 'SFW_SUB_MONITOR_PRINTMEDIA_POLARIS_KLASSEKAMPEN_MND_GA_R_X',
  SfwSubMonitorPrintmediaPolarisPartnerNoRX = 'SFW_SUB_MONITOR_PRINTMEDIA_POLARIS_PARTNER_NO_R_X',
  SfwSubMonitorPrintmediaSchibstedNorwayPartnerNoRX = 'SFW_SUB_MONITOR_PRINTMEDIA_SCHIBSTED_NORWAY_PARTNER_NO_R_X',
  SfwSubMonitorReportsMndGaRX = 'SFW_SUB_MONITOR_REPORTS_MND_GA_R_X',
  SfwSubMonitorSocialMediaPartnerGaRX = 'SFW_SUB_MONITOR_SOCIAL_MEDIA_PARTNER_GA_R_X',
  SfwSubMonitorSpeechToTextPartnerNoRX = 'SFW_SUB_MONITOR_SPEECH_TO_TEXT_PARTNER_NO_R_X',
  SfwSubMonitorUkKilderMndGaRX = 'SFW_SUB_MONITOR_UK_KILDER_MND_GA_R_X',
  SfwSubProfessionalCoreMndGaRX = 'SFW_SUB_PROFESSIONAL_CORE_MND_GA_R_X',
  SfwSubProCoreMndGaRX = 'SFW_SUB_PRO_CORE_MND_GA_R_X',
  SfwSubStandardCoreMndGaRX = 'SFW_SUB_STANDARD_CORE_MND_GA_R_X',
  SfwSubStarterCoreMndGaRX = 'SFW_SUB_STARTER_CORE_MND_GA_R_X',
  SfwSubStorywidgetMndGaRX = 'SFW_SUB_STORYWIDGET_MND_GA_R_X',
  SfwSubTiktokYoutubePintrestMentionGaRX = 'SFW_SUB_TIKTOK_YOUTUBE_PINTREST_MENTION_GA_R_X',
  SfwSubUnlimitedContactsMndGaRX = 'SFW_SUB_UNLIMITED_CONTACTS_MND_GA_R_X'
}

/** Autogenerated input type of CommercialProductPurchase */
export type CommercialProductPurchaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  products: Array<CommercialProductPurchaseProduct>;
};

export type CommercialProductPurchaseProduct = {
  productCode: CommercialProduct;
  quantity: Scalars['Int']['input'];
};

/** Autogenerated input type of CommercialProductTrial */
export type CommercialProductTrialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  products: Array<CommercialProduct>;
};

export enum CompanySize {
  Enterprise = 'ENTERPRISE',
  Medium = 'MEDIUM',
  Micro = 'MICRO',
  None = 'NONE',
  Small = 'SMALL'
}

export type Competitor = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  searchProfileId: Scalars['ID']['input'];
};

export type Competitor2 = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  searchProfileIds: Array<Scalars['ID']['input']>;
};

export enum ContactAttribute {
  Address = 'ADDRESS',
  City = 'CITY',
  Company = 'COMPANY',
  Country = 'COUNTRY',
  Description = 'DESCRIPTION',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  JobTitle = 'JOB_TITLE',
  LastName = 'LAST_NAME',
  Mobile = 'MOBILE',
  Phone = 'PHONE',
  Postcode = 'POSTCODE',
  Prefix = 'PREFIX',
  SocialMediaProfiles = 'SOCIAL_MEDIA_PROFILES',
  State = 'STATE'
}

/** Allowed attributes when importing a contact */
export type ContactImportAttributes = {
  address?: InputMaybe<Scalars['String']['input']>;
  agilityPrId: Scalars['ID']['input'];
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  facebookUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedinUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  subjects?: InputMaybe<Array<Scalars['String']['input']>>;
  twitterUrls?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated input type of ContactListCreate */
export type ContactListCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of ContactPersonConnect */
export type ContactPersonConnectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactPersonId: Scalars['ID']['input'];
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
};

/** Autogenerated input type of ContactPersonDelete */
export type ContactPersonDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of ContactPersonDisconnect */
export type ContactPersonDisconnectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactPersonId: Scalars['ID']['input'];
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
};

/** Autogenerated input type of ContactPersonMove */
export type ContactPersonMoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  direction: MoveDirection;
  /** tag id to move */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of ContactPersonRemoveImage */
export type ContactPersonRemoveImageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of ContactPersonUpdate */
export type ContactPersonUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  links?: InputMaybe<Array<LinkInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  publiclyVisible?: InputMaybe<Scalars['Boolean']['input']>;
  resourceImageId?: InputMaybe<Scalars['ID']['input']>;
  responsibility?: InputMaybe<Scalars['String']['input']>;
  scheduledPublishAt?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledUnpublishAt?: InputMaybe<Scalars['DateTime']['input']>;
  showAsPressroomContact?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ContactsFindJournalistImportByList */
export type ContactsFindJournalistImportByListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactListId: Scalars['ID']['input'];
  journalists: Array<FindJournalistImportByListInput>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of ContactsFindJournalistImportBySearchQuery */
export type ContactsFindJournalistImportBySearchQueryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactListId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
  searchQuery: FindJournalistImportBySearchQueryInput;
};

/** Autogenerated input type of ContactsImportAudienceList */
export type ContactsImportAudienceListInput = {
  audienceContactListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of ContactsImportEmailList */
export type ContactsImportEmailListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactListIds: Array<Scalars['ID']['input']>;
  emails: Array<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of ContactsImportFile */
export type ContactsImportFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactListIds: Array<Scalars['ID']['input']>;
  /** zlib-deflated base64 encoded JSON matrix of contacts */
  contacts: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  mapping: Array<InputMaybe<ContactAttribute>>;
  pressroomId: Scalars['ID']['input'];
  skipFirstLine: Scalars['Boolean']['input'];
};

/** Autogenerated input type of ContactsMediaRelationsImport */
export type ContactsMediaRelationsImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactListIds: Array<Scalars['ID']['input']>;
  journalists: Array<MediaRelationsImportInput>;
  pressroomId: Scalars['ID']['input'];
};

export enum CookieSettings {
  All = 'ALL',
  MndCookieConsent = 'MND_COOKIE_CONSENT',
  OnlyEssential = 'ONLY_ESSENTIAL'
}

/** Autogenerated input type of CopilotUrlSave */
export type CopilotUrlSaveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

/** Autogenerated input type of CopilotUrlUpdate */
export type CopilotUrlUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

/** Autogenerated input type of CreateClipboard */
export type CreateClipboardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  payload: ClipboardInput;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateNewsAlert */
export type CreateNewsAlertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newsAlert: NewsAlertInput;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateOrUpdateQuotationSettings */
export type CreateOrUpdateQuotationSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  settings: SettingsFieldsInput;
  type: MaterialType;
};

/** Autogenerated input type of CreateOrUpdateSearchGroup */
export type CreateOrUpdateSearchGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateSearchProfile */
export type CreateSearchProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
  regenerate?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<SearchProfileInput>;
};

/** Autogenerated input type of CreateSimpleSearchProfile */
export type CreateSimpleSearchProfileInput = {
  brandName: Scalars['String']['input'];
  brandUrl: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Scope down the search to a specific entity type. */
  entityType?: InputMaybe<Scalars['String']['input']>;
  identifyCompetitors?: InputMaybe<Scalars['Boolean']['input']>;
  languageIso: MonitorProfileLanguage;
  /** The number of competitors to identify. */
  numberOfCompetitors?: InputMaybe<Scalars['Int']['input']>;
  /** Use this to create competitor search profiles. */
  parentSimpleSearchProfileId?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

export type CroppingDataInput = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
};

export type CroppingInput = {
  data?: InputMaybe<CroppingDataInput>;
  imageId: Scalars['ID']['input'];
};

export enum CurrentPlan {
  BasicPlus = 'BASIC_PLUS',
  Enterprise = 'ENTERPRISE',
  Free = 'FREE',
  Go = 'GO',
  Pro = 'PRO',
  Professional = 'PROFESSIONAL',
  Standard = 'STANDARD',
  Starter = 'STARTER'
}

export type DateTimeRangeInput = {
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Autogenerated input type of DeleteClipboard */
export type DeleteClipboardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteMaterial */
export type DeleteMaterialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  typename: MaterialType;
  unscheduleRelatedMaterial?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of DeleteNewsAlert */
export type DeleteNewsAlertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newsAlertId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteScheduledSendExclusive */
export type DeleteScheduledSendExclusiveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sendExclusiveId: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteSearchProfile */
export type DeleteSearchProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteSimpleSearchProfile */
export type DeleteSimpleSearchProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of DocumentRemoveResourceDocument */
export type DocumentRemoveResourceDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of DocumentSyncCloudinaryUpload */
export type DocumentSyncCloudinaryUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cloudinaryBytes: Scalars['Int']['input'];
  cloudinaryFormat: Scalars['String']['input'];
  cloudinaryId: Scalars['ID']['input'];
  cloudinaryVersion: Scalars['Int']['input'];
  /** Updates this document if present, otherwise will create. */
  documentId?: InputMaybe<Scalars['ID']['input']>;
  fileName: Scalars['String']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of DocumentUpdate */
export type DocumentUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copyright?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  licenseId?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
  resourceDocumentId?: InputMaybe<Scalars['ID']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of EditNewsAlert */
export type EditNewsAlertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newsAlert: NewsAlertInput;
  newsAlertId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

export enum EmailListReason {
  All = 'ALL',
  Bounced = 'BOUNCED',
  Duplicate = 'DUPLICATE',
  Invalid = 'INVALID',
  Unsubscribed = 'UNSUBSCRIBED'
}

/** Attributes representing the settings for the Analyze reports */
export type EmailReportsAnalyzeReportsSettingsAttributes = {
  statsItem2day: Scalars['Boolean']['input'];
  statsItem2weeks: Scalars['Boolean']['input'];
  statsItem3day: Scalars['Boolean']['input'];
  statsItemDay: Scalars['Boolean']['input'];
  statsItemEmails: Array<Scalars['String']['input']>;
  statsItemWeek: Scalars['Boolean']['input'];
  statsOverviewEmails: Array<Scalars['String']['input']>;
  statsOverviewSetting?: InputMaybe<Scalars['String']['input']>;
};

export type EmailReportsIdeationEmailReportsSettingsAttributes = {
  ideationEmailFrequency?: InputMaybe<IdeationEmailReportFrequency>;
  ideationEmailReceivers?: InputMaybe<Array<Scalars['String']['input']>>;
  ideationMailFrequency?: InputMaybe<IdeationEmailReportFrequency>;
};

/** Attributes representing the settings for the PR Impact report */
export type EmailReportsPrImpactReportsSettingsAttributes = {
  prImpactEmailReceivers: Array<Scalars['String']['input']>;
  prImpactEmailSendoutActive: Scalars['Boolean']['input'];
};

export type EmailSettingsDesignInput = {
  bodyColor: Scalars['String']['input'];
  bodyFont: Scalars['String']['input'];
  dividerColor: Scalars['String']['input'];
  headerColor: Scalars['String']['input'];
  headerFont: Scalars['String']['input'];
  linkColor: Scalars['String']['input'];
  primaryBrandColor?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of EmailSettingsDesignUpdate */
export type EmailSettingsDesignUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  design?: InputMaybe<EmailSettingsDesignInput>;
  enabled: Scalars['Boolean']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of EmailSettingsGeneralResendEmail */
export type EmailSettingsGeneralResendEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of EmailSettingsGeneralReset */
export type EmailSettingsGeneralResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of EmailSettingsGeneralUpdate */
export type EmailSettingsGeneralUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  type: EmailSettingsTypeInput;
};

export enum EmailSettingsTypeInput {
  AuthenticatedDomain = 'AUTHENTICATED_DOMAIN',
  Default = 'DEFAULT',
  ReplyTo = 'REPLY_TO'
}

/** Autogenerated input type of EmailSettingsValidateAuthenticatedDomain */
export type EmailSettingsValidateAuthenticatedDomainInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of EventUpdate */
export type EventUpdateInput = {
  allDay?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageCaption?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  resourceImageId?: InputMaybe<Scalars['ID']['input']>;
  signupUrl?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

export enum Feature {
  Analyze = 'analyze',
  AnalyzeLight = 'analyzeLight',
  AnalyzeMaterial = 'analyzeMaterial',
  AudienceBuilder = 'audienceBuilder',
  AudienceBuilderUpsell = 'audienceBuilderUpsell',
  AudienceTargeting = 'audienceTargeting',
  BrandReports = 'brandReports',
  CoPilotAiWriter = 'coPilotAiWriter',
  CoPilotContactCleaner = 'coPilotContactCleaner',
  CoPilotContext = 'coPilotContext',
  CoPilotIdeation = 'coPilotIdeation',
  CoPilotPrImpactMail = 'coPilotPrImpactMail',
  CoPilotPublishSidebar = 'coPilotPublishSidebar',
  CoPilotRecommendedJournalists = 'coPilotRecommendedJournalists',
  ContactInsight = 'contactInsight',
  ContactsDetails = 'contactsDetails',
  ContactsUnlimited = 'contactsUnlimited',
  CoverageReports = 'coverageReports',
  CustomEmailDomainAndDesign = 'customEmailDomainAndDesign',
  DashboardInsights = 'dashboardInsights',
  DuplicateMaterial = 'duplicateMaterial',
  EmailCredits1000 = 'emailCredits1000',
  EmailCredits3000 = 'emailCredits3000',
  EmailCredits10000 = 'emailCredits10000',
  EmailCredits20000 = 'emailCredits20000',
  EmailCredits50000 = 'emailCredits50000',
  EmailCreditsExtra5000 = 'emailCreditsExtra5000',
  EmailCreditsExtra10000 = 'emailCreditsExtra10000',
  FindContacts = 'findContacts',
  GoogleAnalytics = 'googleAnalytics',
  HostedNewsroom = 'hostedNewsroom',
  LabelEngagement = 'labelEngagement',
  Mention = 'mention',
  MndReach = 'mndReach',
  Monitor = 'monitor',
  MonitorEther = 'monitorEther',
  MonitorMultipleLanguages = 'monitorMultipleLanguages',
  MonitorPaywallBonnier = 'monitorPaywallBonnier',
  MonitorPaywallGota = 'monitorPaywallGota',
  MonitorPaywallNtm = 'monitorPaywallNtm',
  MonitorPaywallSchibsted = 'monitorPaywallSchibsted',
  MonitorPaywallStampen = 'monitorPaywallStampen',
  MonitorPaywallTt = 'monitorPaywallTt',
  MonitorPrintMedia = 'monitorPrintMedia',
  MonitorSearchProfiles10 = 'monitorSearchProfiles10',
  MonitorSearchProfilesExtra5 = 'monitorSearchProfilesExtra5',
  MonitorSearchProfilesUnlimited = 'monitorSearchProfilesUnlimited',
  MonitorSentiment = 'monitorSentiment',
  MonitorSocialMedia = 'monitorSocialMedia',
  MonitorSwedishPaywalls = 'monitorSwedishPaywalls',
  Newsroom = 'newsroom',
  NewsroomSocialFeeds = 'newsroomSocialFeeds',
  Pitch = 'pitch',
  PlanBasicPlus = 'planBasicPlus',
  PlanEnterprise = 'planEnterprise',
  PlanGo = 'planGo',
  PlanPro = 'planPro',
  PlanProfessional = 'planProfessional',
  PlanStandard = 'planStandard',
  PlanStarter = 'planStarter',
  PrImpact = 'prImpact',
  PrImpactLimited = 'prImpactLimited',
  PrImpactQuotations = 'prImpactQuotations',
  PublishAutomatedTopics = 'publishAutomatedTopics',
  PublishCategories10 = 'publishCategories10',
  PublishImages = 'publishImages',
  PublishReadabilityAnalysis = 'publishReadabilityAnalysis',
  PublishRelatedMaterial = 'publishRelatedMaterial',
  PublishScheduling = 'publishScheduling',
  PublishSocialMediaChannels = 'publishSocialMediaChannels',
  PublishSuggestPublishingTimes = 'publishSuggestPublishingTimes',
  ResourceImages = 'resourceImages',
  SocialMediaPublishing = 'socialMediaPublishing',
  StoryWidget = 'storyWidget',
  TagManagement = 'tagManagement',
  WebservicePressroom = 'webservicePressroom'
}

/** Autogenerated input type of FeatureRequestTrial2 */
export type FeatureRequestTrial2Input = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  features: Array<Feature>;
  pressroomId: Scalars['ID']['input'];
};

export type FindJournalistImportByListInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type FindJournalistImportBySearchQueryInput = {
  outletIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  topicIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated input type of FindOrCreateShareableClipboardReportLink */
export type FindOrCreateShareableClipboardReportLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clipboardId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of FindOrCreateShareablePrImpactReportLink */
export type FindOrCreateShareablePrImpactReportLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
  pressroomId: Scalars['ID']['input'];
};

export enum GeneratedByAiWriterTemplate {
  GeneralTemplate = 'GENERAL_TEMPLATE',
  IdeationEmail = 'IDEATION_EMAIL',
  IdeationInTool = 'IDEATION_IN_TOOL',
  NewAward = 'NEW_AWARD',
  NewEmployee = 'NEW_EMPLOYEE',
  NewPartnership = 'NEW_PARTNERSHIP',
  NewPressInvitation = 'NEW_PRESS_INVITATION',
  NewProduct = 'NEW_PRODUCT'
}

export enum GeographicalImpactSortOrder {
  Hits = 'HITS',
  Reach = 'REACH'
}

/** Autogenerated input type of HostedNewsroomSettingsUpdate */
export type HostedNewsroomSettingsUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cname?: InputMaybe<Scalars['String']['input']>;
  cnameCookieTermsUrl?: InputMaybe<Scalars['String']['input']>;
  cookieSettings?: InputMaybe<CookieSettings>;
  customFooter?: InputMaybe<Scalars['Boolean']['input']>;
  customHeader?: InputMaybe<Scalars['Boolean']['input']>;
  footerCss?: InputMaybe<Scalars['String']['input']>;
  footerHtml?: InputMaybe<Scalars['String']['input']>;
  headerCss?: InputMaybe<Scalars['String']['input']>;
  headerHtml?: InputMaybe<Scalars['String']['input']>;
  headlineFontFamily?: InputMaybe<Scalars['String']['input']>;
  headlineFontStyle?: InputMaybe<Scalars['String']['input']>;
  iframeUrl?: InputMaybe<Scalars['String']['input']>;
  includeFooter?: InputMaybe<Scalars['Boolean']['input']>;
  includeLogotype?: InputMaybe<Scalars['Boolean']['input']>;
  miscellaneousFontFamily?: InputMaybe<Scalars['String']['input']>;
  miscellaneousFontStyle?: InputMaybe<Scalars['String']['input']>;
  newsroomId: Scalars['ID']['input'];
  newsroomLayout?: InputMaybe<NewsroomLayout>;
  paragraphBodyFontStyle?: InputMaybe<Scalars['String']['input']>;
  paragraphFontFamily?: InputMaybe<Scalars['String']['input']>;
  paragraphPreambleFontStyle?: InputMaybe<Scalars['String']['input']>;
  subdomain?: InputMaybe<Scalars['String']['input']>;
  useHostedNewsroomUrlForRedirects?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of HostedNewsroomStylePreviewUpdate */
export type HostedNewsroomStylePreviewUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customFooter?: InputMaybe<Scalars['Boolean']['input']>;
  customHeader?: InputMaybe<Scalars['Boolean']['input']>;
  footerCss?: InputMaybe<Scalars['String']['input']>;
  footerHtml?: InputMaybe<Scalars['String']['input']>;
  headerCss?: InputMaybe<Scalars['String']['input']>;
  headerHtml?: InputMaybe<Scalars['String']['input']>;
  headlineFontFamily?: InputMaybe<Scalars['String']['input']>;
  headlineFontStyle?: InputMaybe<Scalars['String']['input']>;
  includeFooter?: InputMaybe<Scalars['Boolean']['input']>;
  includeLogotype?: InputMaybe<Scalars['Boolean']['input']>;
  miscellaneousFontFamily?: InputMaybe<Scalars['String']['input']>;
  miscellaneousFontStyle?: InputMaybe<Scalars['String']['input']>;
  newsroomId: Scalars['ID']['input'];
  paragraphBodyFontStyle?: InputMaybe<Scalars['String']['input']>;
  paragraphFontFamily?: InputMaybe<Scalars['String']['input']>;
  paragraphPreambleFontStyle?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of HubspotCallbackRequest */
export type HubspotCallbackRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  type: HubspotCallbacks;
};

export enum HubspotCallbacks {
  CoverageReport = 'COVERAGE_REPORT',
  CoverageReportMonitor = 'COVERAGE_REPORT_MONITOR'
}

/** Autogenerated input type of HubspotDemoRequest */
export type HubspotDemoRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  type: HubspotDemos;
};

export enum HubspotDemos {
  Analyze = 'ANALYZE',
  AudienceBuilder = 'AUDIENCE_BUILDER',
  InTool = 'IN_TOOL',
  Monitor = 'MONITOR',
  PlanUpsell = 'PLAN_UPSELL',
  PrImpact = 'PR_IMPACT',
  Reports = 'REPORTS',
  TrialCustomerUpgrade = 'TRIAL_CUSTOMER_UPGRADE'
}

export enum IdeationEmailReportFrequency {
  Monthly = 'MONTHLY',
  Never = 'NEVER',
  Weekly = 'WEEKLY'
}

export enum ImageSort {
  AscendingCreatedAt = 'AscendingCreatedAt',
  AscendingHeader = 'AscendingHeader',
  DescendingCreatedAt = 'DescendingCreatedAt',
  DescendingHeader = 'DescendingHeader'
}

export enum ImageStatusFilter {
  Draft = 'draft',
  Expiration = 'expiration',
  Published = 'published',
  Scheduled = 'scheduled',
  Sticky = 'sticky'
}

/** Autogenerated input type of ImageSyncCloudinaryUpload */
export type ImageSyncCloudinaryUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cloudinaryBytes?: InputMaybe<Scalars['Int']['input']>;
  cloudinaryFormat?: InputMaybe<Scalars['String']['input']>;
  cloudinaryHeight?: InputMaybe<Scalars['Int']['input']>;
  cloudinaryId: Scalars['ID']['input'];
  cloudinaryVersion: Scalars['Int']['input'];
  cloudinaryWidth?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  licenseId?: InputMaybe<Scalars['ID']['input']>;
  photographer?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  summary?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ImagesDelete */
export type ImagesDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deleteGlobally: Scalars['Boolean']['input'];
  /** array of image ids to delete */
  ids: Array<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of ImagesImportFromOtherNewsroom */
export type ImagesImportFromOtherNewsroomInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  imageIds2?: InputMaybe<Array<Scalars['ID']['input']>>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of ImagesUpdate */
export type ImagesUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copyright?: InputMaybe<Scalars['String']['input']>;
  croppings?: InputMaybe<Array<CroppingInput>>;
  header?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  licenseId?: InputMaybe<Scalars['ID']['input']>;
  photographer?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  scheduledFor?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<MaterialStatus>;
  summary?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Represents arguments for all the pages in Insights Menu */
export type InsightsSearchInput = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  profiles?: InputMaybe<Array<Scalars['ID']['input']>>;
  sources?: InputMaybe<Array<MonitorMediaFilterType>>;
  /** To use, select RANGE as timespan. */
  timeRange?: InputMaybe<MonitorTimeRange>;
  timespan?: InputMaybe<MonitorTimespan>;
};

/** Autogenerated input type of IntroSurveyCompletion */
export type IntroSurveyCompletionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

export type LinkInput = {
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type LinkedinImageInput = {
  altText?: InputMaybe<Scalars['String']['input']>;
  cropping?: InputMaybe<CroppingDataInput>;
  id: Scalars['ID']['input'];
};

export type LinkedinLinkInput = {
  cropping?: InputMaybe<CroppingDataInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageAltText?: InputMaybe<Scalars['String']['input']>;
  /** Will be overwritten by resource_image_id if that is included */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Will overwrite image_url */
  resourceImageId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

/** Autogenerated input type of LinkedinPostCreate */
export type LinkedinPostCreateInput = {
  channelId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  link?: InputMaybe<LinkedinLinkInput>;
  /** Use link */
  linkDescription?: InputMaybe<Scalars['String']['input']>;
  /** Use link */
  linkImage?: InputMaybe<Scalars['String']['input']>;
  /** Use link */
  linkTitle?: InputMaybe<Scalars['String']['input']>;
  /** Use link */
  linkUrl?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  /** Use resourceImages */
  resourceImageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  resourceImages?: InputMaybe<Array<LinkedinImageInput>>;
  /** Send null to send immediately */
  scheduledFor?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Autogenerated input type of LinkedinPostDelete */
export type LinkedinPostDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  postId: Scalars['ID']['input'];
};

/** Autogenerated input type of LinkedinPostEdit */
export type LinkedinPostEditInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  link?: InputMaybe<LinkedinLinkInput>;
  /** Use link */
  linkDescription?: InputMaybe<Scalars['String']['input']>;
  /** Use link */
  linkImage?: InputMaybe<Scalars['String']['input']>;
  /** Use link */
  linkTitle?: InputMaybe<Scalars['String']['input']>;
  /** Use link */
  linkUrl?: InputMaybe<Scalars['String']['input']>;
  postId: Scalars['ID']['input'];
  /** Use resourceImages */
  resourceImageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  resourceImages?: InputMaybe<Array<LinkedinImageInput>>;
  /** Send null to send immediately */
  scheduledFor?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum LinkedinPostState {
  /** Scheduled and not yet processed by the backend */
  Default = 'DEFAULT',
  Error = 'ERROR',
  /** Currently running backend logic to upload media and post to Linkedin */
  Posting = 'POSTING',
  /** Post is sent, see timestamp in publishedAt */
  Published = 'PUBLISHED',
  /** Scheduled and processed by the backend */
  Queued = 'QUEUED'
}

/** Filter options for geographic location. */
export type LocationFilterInput = {
  /** Country to filter for. */
  country: Scalars['String']['input'];
  /** Regions to filter for. */
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated input type of MaterialAlertSubscriptionEmailsUpdate */
export type MaterialAlertSubscriptionEmailsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  disableAlertSubscriptionEmails: Scalars['Boolean']['input'];
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
};

/** Autogenerated input type of MaterialArchive */
export type MaterialArchiveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  type: MaterialType;
};

/** Autogenerated input type of MaterialChannelMessageUpdate */
export type MaterialChannelMessageUpdateInput = {
  channelId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  type: MaterialType;
};

/** Autogenerated input type of MaterialChannelsAdd */
export type MaterialChannelsAddInput = {
  channelIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  type: MaterialType;
};

/** Autogenerated input type of MaterialChannelsRemove */
export type MaterialChannelsRemoveInput = {
  channelIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  type: MaterialType;
};

/** Autogenerated input type of MaterialChannelsUpdate */
export type MaterialChannelsUpdateInput = {
  channels?: InputMaybe<Array<ChannelIdMessageTuple>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  type: MaterialType;
};

/** Autogenerated input type of MaterialClone */
export type MaterialCloneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
  targetPressroomIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated input type of MaterialConnect */
export type MaterialConnectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  hostMaterialId: Scalars['ID']['input'];
  hostMaterialType: MaterialType;
  materialIdToConnect: Scalars['ID']['input'];
  materialTypeToConnect: MaterialType;
};

/** Autogenerated input type of MaterialContactListsAdd */
export type MaterialContactListsAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactLists: Array<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  type: MaterialType;
};

/** Autogenerated input type of MaterialContactListsRemove */
export type MaterialContactListsRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactLists: Array<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  type: MaterialType;
};

/** Autogenerated input type of MaterialContactListsUpdate */
export type MaterialContactListsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactLists: Array<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  type: MaterialType;
};

/** Autogenerated input type of MaterialDisconnect */
export type MaterialDisconnectInput = {
  /** A check to avoid removing header image of material when disconnecting in body field */
  avoidRemovingHostResourceImage?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  connectedMaterialId: Scalars['ID']['input'];
  connectedMaterialType: MaterialType;
  hostMaterialId: Scalars['ID']['input'];
  hostMaterialType: MaterialType;
};

/** Autogenerated input type of MaterialDuplicate */
export type MaterialDuplicateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
};

/** Autogenerated input type of MaterialFeedback */
export type MaterialFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  positive?: InputMaybe<Scalars['Boolean']['input']>;
  type: MaterialType;
};

export type MaterialIdTuple = {
  id: Scalars['ID']['input'];
  type: MaterialType;
};

/** Autogenerated input type of MaterialLinksUpdate */
export type MaterialLinksUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  links: Array<LinkInput>;
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
};

export enum MaterialListSort {
  AscendingCreatedAt = 'ASCENDING_CREATED_AT',
  AscendingEmailsOpened = 'ASCENDING_EMAILS_OPENED',
  AscendingEmailsSent = 'ASCENDING_EMAILS_SENT',
  AscendingEmailsUniqueOpened = 'ASCENDING_EMAILS_UNIQUE_OPENED',
  AscendingName = 'ASCENDING_NAME',
  AscendingPulishedAt = 'ASCENDING_PULISHED_AT',
  AscendingQuotations = 'ASCENDING_QUOTATIONS',
  AscendingViews = 'ASCENDING_VIEWS',
  DescendingCreatedAt = 'DESCENDING_CREATED_AT',
  DescendingEmailsOpened = 'DESCENDING_EMAILS_OPENED',
  DescendingEmailsSent = 'DESCENDING_EMAILS_SENT',
  DescendingEmailsUniqueOpened = 'DESCENDING_EMAILS_UNIQUE_OPENED',
  DescendingName = 'DESCENDING_NAME',
  DescendingQuotations = 'DESCENDING_QUOTATIONS',
  DescendingViews = 'DESCENDING_VIEWS',
  DescendingPublishedAt = 'descending_published_at'
}

/** Autogenerated input type of MaterialNetworkContactAdd */
export type MaterialNetworkContactAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
  networkContactIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated input type of MaterialNetworkContactRemove */
export type MaterialNetworkContactRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
  networkContactIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated input type of MaterialNetworkContactUpdate */
export type MaterialNetworkContactUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
  networkContactIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated input type of MaterialPublicationTimeUpdate */
export type MaterialPublicationTimeUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  materialId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
  publishRelatedMaterial?: InputMaybe<Scalars['Boolean']['input']>;
  /** Required when expires_at is used. */
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  type: MaterialType;
};

/** Autogenerated input type of MaterialPublish */
export type MaterialPublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
  publishRelatedMaterial?: InputMaybe<Scalars['Boolean']['input']>;
  type: MaterialType;
};

export enum MaterialRecipientsFilter {
  AudienceBuilderImport = 'AudienceBuilderImport'
}

export enum MaterialRecipientsSort {
  AscendingClicked = 'ASCENDING_CLICKED',
  AscendingName = 'ASCENDING_NAME',
  AscendingOpened = 'ASCENDING_OPENED',
  AscendingUniqueOpened = 'ASCENDING_UNIQUE_OPENED',
  DescendingClicked = 'DESCENDING_CLICKED',
  DescendingName = 'DESCENDING_NAME',
  DescendingOpened = 'DESCENDING_OPENED',
  DescendingUniqueOpened = 'DESCENDING_UNIQUE_OPENED'
}

/** Autogenerated input type of MaterialRecommendedContactRemove */
export type MaterialRecommendedContactRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
  recommendedContactIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated input type of MaterialRecommendedContactUpdate */
export type MaterialRecommendedContactUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
  recommendedContactIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated input type of MaterialRegionsAdd */
export type MaterialRegionsAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  regions: Array<Scalars['String']['input']>;
  type: MaterialType;
};

/** Autogenerated input type of MaterialRegionsRemove */
export type MaterialRegionsRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  regions: Array<Scalars['String']['input']>;
  type: MaterialType;
};

/** Autogenerated input type of MaterialRegionsUpdate */
export type MaterialRegionsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  regions: Array<Scalars['String']['input']>;
  type: MaterialType;
};

export enum MaterialStatus {
  /** This value will only exist for Story Materials */
  Archived = 'archived',
  Draft = 'draft',
  Published = 'published',
  Scheduled = 'scheduled'
}

export enum MaterialStatusFilter {
  Archived = 'archived',
  Draft = 'draft',
  Expiration = 'expiration',
  Published = 'published',
  Scheduled = 'scheduled',
  Sticky = 'sticky'
}

/** Autogenerated input type of MaterialSubjectsAdd */
export type MaterialSubjectsAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  subjects: Array<Scalars['String']['input']>;
  type: MaterialType;
};

/** Autogenerated input type of MaterialSubjectsRemove */
export type MaterialSubjectsRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  subjects: Array<Scalars['String']['input']>;
  type: MaterialType;
};

/** Autogenerated input type of MaterialSubjectsUpdate */
export type MaterialSubjectsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  subjects: Array<Scalars['String']['input']>;
  type: MaterialType;
};

/** Autogenerated input type of MaterialTag */
export type MaterialTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  tag: Scalars['String']['input'];
  type: MaterialType;
};

/** Autogenerated input type of MaterialTemplateAdd */
export type MaterialTemplateAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialType: MaterialType;
  payload: MaterialTemplateAddInputWrapper;
  pressroomId: Scalars['ID']['input'];
};

export type MaterialTemplateAddInputWrapper = {
  newAwardInput?: InputMaybe<NewAwardInput>;
  newEmployeeInput?: InputMaybe<NewEmployeeInput>;
  newPartnershipInput?: InputMaybe<NewPartnershipInput>;
  newPressInvitationInput?: InputMaybe<NewPressInvitationInput>;
  newProductInput?: InputMaybe<NewProductInput>;
};

export enum MaterialType {
  BlogPost = 'BlogPost',
  Document = 'Document',
  Event = 'Event',
  Image = 'Image',
  News = 'News',
  Pressrelease = 'Pressrelease',
  Video = 'Video'
}

/** Autogenerated input type of MaterialUnschedule */
export type MaterialUnscheduleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  idTuples?: InputMaybe<Array<InputMaybe<MaterialIdTuple>>>;
  unscheduleRelatedMaterial?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of MaterialUntag */
export type MaterialUntagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  tag: Scalars['String']['input'];
  type: MaterialType;
};

/** Autogenerated input type of MaterialVisibilityUpdate */
export type MaterialVisibilityUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  type: MaterialType;
  visibility: Visibility;
};

/** Autogenerated input type of MaterialsConnectionUpdate */
export type MaterialsConnectionUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  connectedMaterials: Array<MaterialIdTuple>;
  hostMaterialId: Scalars['ID']['input'];
  hostMaterialType: MaterialType;
  relatedMaterialTypes: Array<MaterialType>;
  unscheduleRelatedMaterial?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MediaFeedPageInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  publishedAt: Scalars['String']['input'];
  reach?: InputMaybe<Scalars['Float']['input']>;
};

export enum MediaLayout {
  Contained = 'CONTAINED',
  Dynamic = 'DYNAMIC',
  GoldenRatio = 'GOLDEN_RATIO'
}

export type MediaRelationsImportInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  socialMediaProfiles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum MndReachItemType {
  Journalist = 'JOURNALIST',
  Outlet = 'OUTLET'
}

export enum MndReachRelevance {
  HighlyRelevant = 'HIGHLY_RELEVANT',
  Irrelevant = 'IRRELEVANT',
  PotentiallyRelevant = 'POTENTIALLY_RELEVANT',
  Relevant = 'RELEVANT'
}

export enum MndReachSortOrder {
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
  OutletNameAsc = 'OutletNameAsc',
  OutletNameDesc = 'OutletNameDesc',
  ReachAsc = 'ReachAsc',
  ReachDesc = 'ReachDesc',
  RelevanceAsc = 'RelevanceAsc',
  RelevanceDesc = 'RelevanceDesc'
}

export enum MndReachTopic {
  Advertising = 'ADVERTISING',
  Agriculture = 'AGRICULTURE',
  Animal = 'ANIMAL',
  Architecture = 'ARCHITECTURE',
  ArtsAndEntertainment = 'ARTS_AND_ENTERTAINMENT',
  ArtsCultureEntertainmentAndMedia = 'ARTS_CULTURE_ENTERTAINMENT_AND_MEDIA',
  Automotive = 'AUTOMOTIVE',
  BusinessInformation = 'BUSINESS_INFORMATION',
  ConstructionAndProperty = 'CONSTRUCTION_AND_PROPERTY',
  Crime = 'CRIME',
  CrimeLawAndJustice = 'CRIME_LAW_AND_JUSTICE',
  DesignAndEngineering = 'DESIGN_AND_ENGINEERING',
  DesignVisualArts = 'DESIGN_VISUAL_ARTS',
  EconomyBusinessAndFinance = 'ECONOMY_BUSINESS_AND_FINANCE',
  Education = 'EDUCATION',
  Employment = 'EMPLOYMENT',
  EnergyAndResource = 'ENERGY_AND_RESOURCE',
  Environment = 'ENVIRONMENT',
  Esports = 'ESPORTS',
  ExerciseAndFitness = 'EXERCISE_AND_FITNESS',
  Family = 'FAMILY',
  Fashion = 'FASHION',
  FinancialAndBusinessService = 'FINANCIAL_AND_BUSINESS_SERVICE',
  FishingIndustry = 'FISHING_INDUSTRY',
  FoodAndDrinkEnthusiasm = 'FOOD_AND_DRINK_ENTHUSIASM',
  Gardening = 'GARDENING',
  GovernmentHealthCare = 'GOVERNMENT_HEALTH_CARE',
  HealthAndBeautyProduct = 'HEALTH_AND_BEAUTY_PRODUCT',
  HealthTreatment = 'HEALTH_TREATMENT',
  History = 'HISTORY',
  Hobby = 'HOBBY',
  IndustrialProduction = 'INDUSTRIAL_PRODUCTION',
  InformationTechnologyAndComputerScience = 'INFORMATION_TECHNOLOGY_AND_COMPUTER_SCIENCE',
  InfrastructureProjects = 'INFRASTRUCTURE_PROJECTS',
  InteriorDecoration = 'INTERIOR_DECORATION',
  Labour = 'LABOUR',
  Lifestyle = 'LIFESTYLE',
  LifestyleAndLeisure = 'LIFESTYLE_AND_LEISURE',
  Logistics = 'LOGISTICS',
  MachineManufacturing = 'MACHINE_MANUFACTURING',
  Management = 'MANAGEMENT',
  Media = 'MEDIA',
  MentalHealth = 'MENTAL_HEALTH',
  Music = 'MUSIC',
  NewspaperAndMagazine = 'NEWSPAPER_AND_MAGAZINE',
  NewProductOrService = 'NEW_PRODUCT_OR_SERVICE',
  OilAndGasIndustry = 'OIL_AND_GAS_INDUSTRY',
  Pharmaceutical = 'PHARMACEUTICAL',
  Politics = 'POLITICS',
  PublicEmployees = 'PUBLIC_EMPLOYEES',
  Radio = 'RADIO',
  RealEstate = 'REAL_ESTATE',
  RegionalGovernmentAndAuthority = 'REGIONAL_GOVERNMENT_AND_AUTHORITY',
  Religion = 'RELIGION',
  RenewableEnergy = 'RENEWABLE_ENERGY',
  Retail = 'RETAIL',
  ScienceAndTechnology = 'SCIENCE_AND_TECHNOLOGY',
  ScientificResearch = 'SCIENTIFIC_RESEARCH',
  ShippingService = 'SHIPPING_SERVICE',
  SocialProblem = 'SOCIAL_PROBLEM',
  Sport = 'SPORT',
  Students = 'STUDENTS',
  TechnologyAndEngineering = 'TECHNOLOGY_AND_ENGINEERING',
  TelecommunicationService = 'TELECOMMUNICATION_SERVICE',
  Tourism = 'TOURISM',
  Transport = 'TRANSPORT',
  TravelAndTourism = 'TRAVEL_AND_TOURISM'
}

export enum MonitorAverageHitsTimeUnit {
  Days = 'DAYS',
  Hours = 'HOURS'
}

export enum MonitorMediaCategory {
  Blogs = 'BLOGS',
  Ether = 'ETHER',
  Facebook = 'FACEBOOK',
  Forums = 'FORUMS',
  Instagram = 'INSTAGRAM',
  Print = 'PRINT',
  Twitter = 'TWITTER',
  Web = 'WEB',
  Youtube = 'YOUTUBE'
}

export enum MonitorMediaFilterType {
  Blogs = 'BLOGS',
  Ether = 'ETHER',
  Facebook = 'FACEBOOK',
  Forums = 'FORUMS',
  Instagram = 'INSTAGRAM',
  Print = 'PRINT',
  Twitter = 'TWITTER',
  Web = 'WEB'
}

export enum MonitorOrderBy {
  Date = 'DATE',
  Reach = 'REACH'
}

export enum MonitorProfileCountry {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gr = 'GR',
  Gt = 'GT',
  Gu = 'GU',
  Gy = 'GY',
  Hk = 'HK',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mr = 'MR',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Sv = 'SV',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Uk = 'UK',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Ws = 'WS',
  Ye = 'YE',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

export enum MonitorProfileLanguage {
  Be = 'BE',
  Bg = 'BG',
  Cs = 'CS',
  Da = 'DA',
  De = 'DE',
  El = 'EL',
  En = 'EN',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fr = 'FR',
  He = 'HE',
  Hr = 'HR',
  Id = 'ID',
  Is = 'IS',
  It = 'IT',
  Ka = 'KA',
  Lt = 'LT',
  Lv = 'LV',
  Mk = 'MK',
  Nl = 'NL',
  No = 'NO',
  Pl = 'PL',
  Pt = 'PT',
  Ro = 'RO',
  Ru = 'RU',
  Sk = 'SK',
  Sl = 'SL',
  Sr = 'SR',
  Sv = 'SV',
  Tr = 'TR',
  Uk = 'UK',
  Vi = 'VI'
}

export enum MonitorProfileMediaCategory {
  Ether = 'ETHER',
  Print = 'PRINT',
  SocialMedia = 'SOCIAL_MEDIA',
  Web = 'WEB'
}

export enum MonitorProfileType {
  MonitorBooleanSearchProfile = 'MonitorBooleanSearchProfile',
  MonitorProfile = 'MonitorProfile'
}

export enum MonitorSocialMediaSource {
  Blogs = 'BLOGS',
  Facebook = 'FACEBOOK',
  Forums = 'FORUMS',
  Instagram = 'INSTAGRAM',
  Microblogs = 'MICROBLOGS',
  Netsociety = 'NETSOCIETY',
  Others = 'OTHERS',
  Twitter = 'TWITTER',
  Youtube = 'YOUTUBE'
}

export enum MonitorSourceVerification {
  Rejected = 'REJECTED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

export type MonitorTimeRange = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export enum MonitorTimespan {
  LastYear = 'LAST_YEAR',
  Month = 'MONTH',
  Range = 'RANGE',
  SevenDays = 'SEVEN_DAYS',
  ThirtyDays = 'THIRTY_DAYS',
  Today = 'TODAY',
  Week = 'WEEK',
  Yesterday = 'YESTERDAY'
}

export enum MoveDirection {
  Down = 'DOWN',
  Up = 'UP'
}

/** Autogenerated input type of MoveSearchProfileToGroup */
export type MoveSearchProfileToGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
  profileId: Scalars['ID']['input'];
};

export enum NetworkContactActivityType {
  Alert = 'alert',
  ContactCreated = 'contact_created',
  ContactMessage = 'contact_message',
  Distribution = 'distribution',
  MediaHit = 'media_hit',
  Subscription = 'subscription',
  Unsubscription = 'unsubscription'
}

/** Autogenerated input type of NetworkContactAdd */
export type NetworkContactAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  networkContactListId?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

export enum NetworkContactExclude {
  AudienceBuilderImport = 'AudienceBuilderImport',
  AudienceTargetingImport = 'AudienceTargetingImport'
}

export enum NetworkContactExportFileFormat {
  Csv = 'CSV',
  Xlsx = 'XLSX'
}

/** Autogenerated input type of NetworkContactExport */
export type NetworkContactExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileFormat: NetworkContactExportFileFormat;
  networkContactListId?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

export enum NetworkContactFilter {
  AudienceBuilderImport = 'AudienceBuilderImport',
  AudienceTargetingImport = 'AudienceTargetingImport',
  FindJournalistImport = 'FindJournalistImport',
  Followers = 'Followers',
  LatestImport = 'LatestImport',
  MediaCoverage = 'MediaCoverage',
  NotOnAnyList = 'NotOnAnyList',
  Own = 'Own',
  Subscribing = 'Subscribing',
  Unreachable = 'Unreachable',
  Unsubscribed = 'Unsubscribed'
}

export enum NetworkContactImportedVia {
  AudienceBuilder = 'AUDIENCE_BUILDER',
  AudienceTargeting = 'AUDIENCE_TARGETING',
  FindJournalist = 'FIND_JOURNALIST',
  MediaRelations = 'MEDIA_RELATIONS',
  PitchedContacts = 'PITCHED_CONTACTS',
  RecommendedContacts = 'RECOMMENDED_CONTACTS'
}

/** Autogenerated input type of NetworkContactInviteToFollowBulk */
export type NetworkContactInviteToFollowBulkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NetworkContactFilter>;
  message: Scalars['String']['input'];
  networkContactListId?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

/** Autogenerated input type of NetworkContactInviteToFollow */
export type NetworkContactInviteToFollowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  networkContactIds: Array<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
};

/** Autogenerated input type of NetworkContactListAddContactBulk */
export type NetworkContactListAddContactBulkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NetworkContactFilter>;
  /** Filter from this list. Omit this to filter on all contacts */
  filterFromNetworkContactListId?: InputMaybe<Scalars['ID']['input']>;
  /** The contact list you want to add contacts to */
  networkContactListId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of NetworkContactListAddContact */
export type NetworkContactListAddContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  networkContactIds: Array<Scalars['ID']['input']>;
  networkContactListId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of NetworkContactListAdd */
export type NetworkContactListAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of NetworkContactListRemoveContactBulk */
export type NetworkContactListRemoveContactBulkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NetworkContactFilter>;
  /** Filter from this list. Omit this to filter on all contacts */
  filterFromNetworkContactListId?: InputMaybe<Scalars['ID']['input']>;
  /** The contact list you want to remove contacts from */
  networkContactListId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of NetworkContactListRemoveContact */
export type NetworkContactListRemoveContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  networkContactIds: Array<Scalars['ID']['input']>;
  networkContactListId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of NetworkContactListRemove */
export type NetworkContactListRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  networkContactListId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

export enum NetworkContactListSort {
  ClickRate = 'click_rate',
  LatestAdded = 'latest_added',
  Name = 'name',
  OpenRate = 'open_rate',
  Received = 'received'
}

/** Autogenerated input type of NetworkContactListUpdate */
export type NetworkContactListUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  networkContactListId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of NetworkContactRemoveBulk */
export type NetworkContactRemoveBulkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NetworkContactFilter>;
  networkContactListId?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of NetworkContactRemove */
export type NetworkContactRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

export enum NetworkContactSort {
  AscendingClickRate = 'AscendingClickRate',
  AscendingEmailsReceived = 'AscendingEmailsReceived',
  AscendingName = 'AscendingName',
  AscendingOpenRate = 'AscendingOpenRate',
  DescendingClickRate = 'DescendingClickRate',
  DescendingEmailsReceived = 'DescendingEmailsReceived',
  DescendingName = 'DescendingName',
  DescendingOpenRate = 'DescendingOpenRate'
}

/** Autogenerated input type of NetworkContactUpdate */
export type NetworkContactUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  follower?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  socialMediaProfiles?: InputMaybe<Array<Scalars['String']['input']>>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of NetworkInactiveContactRemove */
export type NetworkInactiveContactRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  ids: Array<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

export type NewAwardInput = {
  awardDescription: Scalars['String']['input'];
  awardMotivation: Scalars['String']['input'];
  awardName: Scalars['String']['input'];
  expertName?: InputMaybe<Scalars['String']['input']>;
  presentedBy: Scalars['String']['input'];
  receivedBy: Scalars['String']['input'];
};

export type NewBrandReportInput = {
  brand: Scalars['String']['input'];
  brandColor?: InputMaybe<Scalars['String']['input']>;
  compareToPreviousYear?: InputMaybe<Scalars['Boolean']['input']>;
  competitors?: InputMaybe<Array<Competitor>>;
  competitors2?: InputMaybe<Array<Competitor2>>;
  coverImageId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  excludedSourceGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does nothing unless timespan is RANGE */
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  locale?: InputMaybe<UserLocale>;
  mediaTypes?: InputMaybe<Array<MonitorMediaCategory>>;
  newCompetitorBrandNames?: InputMaybe<Array<Scalars['String']['input']>>;
  pressroomId: Scalars['ID']['input'];
  searchProfileIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  timespan: BrandReportTimespan;
  title?: InputMaybe<Scalars['String']['input']>;
  /** does nothing unless timespan is RANGE */
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NewCoverageReportInput = {
  brandColor?: InputMaybe<Scalars['String']['input']>;
  coverImageId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<UserLocale>;
  pressroomId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NewEmployeeInput = {
  companyName: Scalars['String']['input'];
  employeeName: Scalars['String']['input'];
  expertName?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
};

export type NewPartnershipInput = {
  companyName: Scalars['String']['input'];
  expertName?: InputMaybe<Scalars['String']['input']>;
  partnerName: Scalars['String']['input'];
  purpose: Scalars['String']['input'];
};

export type NewPressInvitationInput = {
  companyName: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  eventDateTime: Scalars['String']['input'];
  eventLocation: Scalars['String']['input'];
  subjectDescription: Scalars['String']['input'];
};

export type NewProductInput = {
  companyName: Scalars['String']['input'];
  expertName?: InputMaybe<Scalars['String']['input']>;
  productDescription: Scalars['String']['input'];
  productName: Scalars['String']['input'];
};

export type NewReportInputWrapper = {
  newBrandReportInput?: InputMaybe<NewBrandReportInput>;
  newCoverageReportInput?: InputMaybe<NewCoverageReportInput>;
};

export type NewsAlertInput = {
  emailRecipients: Array<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
  /** Omitting this field will assume realtime */
  schedule?: InputMaybe<ScheduleInput>;
  selectedProfileIds: Array<Scalars['ID']['input']>;
  sendRealTime?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of NewsUpdate */
export type NewsUpdateInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  generatedByAiWriter?: InputMaybe<Scalars['Boolean']['input']>;
  generatedByAiWriterTemplate?: InputMaybe<GeneratedByAiWriterTemplate>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageCaption?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  resourceImageId?: InputMaybe<Scalars['ID']['input']>;
  showAiDisclaimerText?: InputMaybe<Scalars['Boolean']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of NewsroomBoilerplateCreate */
export type NewsroomBoilerplateCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of NewsroomBoilerplateDelete */
export type NewsroomBoilerplateDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of NewsroomBoilerplateUpdate */
export type NewsroomBoilerplateUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of NewsroomFeedDelete */
export type NewsroomFeedDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newsroomFeedId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of NewsroomFeedFacebookChoosePage */
export type NewsroomFeedFacebookChoosePageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  newsroomFeedId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated input type of NewsroomFeedRssCreate */
export type NewsroomFeedRssCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedUrl: Scalars['String']['input'];
  pressroomId: Scalars['ID']['input'];
};

export enum NewsroomFeedStatus {
  Failed = 'FAILED',
  InputNeeded = 'INPUT_NEEDED',
  Live = 'LIVE',
  Pending = 'PENDING'
}

/** Autogenerated input type of NewsroomFeedTwitterCreate */
export type NewsroomFeedTwitterCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  /** Twitter username (prefix with "@"), hashtag (prefix with "#") or a query like "from:mynewsdesk exclude:replies" */
  twitterQuery: Scalars['String']['input'];
};

/** Autogenerated input type of NewsroomFeedYoutubeCreate */
export type NewsroomFeedYoutubeCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  username: Scalars['String']['input'];
};

export enum NewsroomLayout {
  Cname = 'CNAME',
  Iframe = 'IFRAME'
}

export type NewsroomLinkInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  linkText: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export enum NewsroomLocale {
  Ar = 'AR',
  BgBg = 'BG_BG',
  CsCz = 'CS_CZ',
  Da = 'DA',
  De = 'DE',
  ElGr = 'EL_GR',
  En = 'EN',
  Es = 'ES',
  EsEs = 'ES_ES',
  Et = 'ET',
  Fi = 'FI',
  Fr = 'FR',
  HrHr = 'HR_HR',
  HuHu = 'HU_HU',
  Id = 'ID',
  It = 'IT',
  Ja = 'JA',
  Km = 'KM',
  Lt = 'LT',
  Lv = 'LV',
  Nb = 'NB',
  Nl = 'NL',
  No = 'NO',
  Pl = 'PL',
  Pt = 'PT',
  PtBr = 'PT_BR',
  PtPt = 'PT_PT',
  Ro = 'RO',
  RoRo = 'RO_RO',
  Ru = 'RU',
  Si = 'SI',
  SkSk = 'SK_SK',
  SlSi = 'SL_SI',
  Sv = 'SV',
  Th = 'TH',
  Tr = 'TR',
  Vi = 'VI',
  Zh = 'ZH',
  ZhCn = 'ZH_CN',
  ZhTw = 'ZH_TW'
}

/** Autogenerated input type of NewsroomSettingsUpdate */
export type NewsroomSettingsUpdateInput = {
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  deleteFavicon?: InputMaybe<Scalars['Boolean']['input']>;
  deleteLogotype?: InputMaybe<Scalars['Boolean']['input']>;
  favicon?: InputMaybe<ResourceImageInput>;
  gaProfileId?: InputMaybe<Scalars['String']['input']>;
  homepageUrl?: InputMaybe<Scalars['String']['input']>;
  licenseId?: InputMaybe<Scalars['ID']['input']>;
  links?: InputMaybe<Array<NewsroomLinkInput>>;
  locale?: InputMaybe<NewsroomLocale>;
  logotype?: InputMaybe<ResourceImageInput>;
  mediaLayout?: InputMaybe<MediaLayout>;
  newsroomId: Scalars['ID']['input'];
  noindexMaterials?: InputMaybe<Scalars['Boolean']['input']>;
  organizationNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  primaryBrandColor?: InputMaybe<Scalars['String']['input']>;
  regionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondaryBrandColor?: InputMaybe<Scalars['String']['input']>;
  shortcut?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  subjectIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tagline?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of NewsroomStylePreviewUpdate */
export type NewsroomStylePreviewUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deleteFavicon?: InputMaybe<Scalars['Boolean']['input']>;
  deleteLogotype?: InputMaybe<Scalars['Boolean']['input']>;
  favicon?: InputMaybe<ResourceImageInput>;
  logotype?: InputMaybe<ResourceImageInput>;
  mediaLayout?: InputMaybe<MediaLayout>;
  newsroomId: Scalars['ID']['input'];
  primaryBrandColor?: InputMaybe<Scalars['String']['input']>;
  secondaryBrandColor?: InputMaybe<Scalars['String']['input']>;
};

export enum Notice {
  G2Review = 'G2Review',
  AiWriter = 'aiWriter',
  AiWriterNewDraft = 'aiWriterNewDraft',
  AudienceBuilderContactsUpdate = 'audienceBuilderContactsUpdate',
  AutomatedTopicsBanner = 'automatedTopicsBanner',
  /** Brand Report banner for H1 2024 */
  BrandReportBannerH12024 = 'brandReportBannerH12024',
  /** Brand Report Banner create express */
  BrandReportCreateExpressBanner = 'brandReportCreateExpressBanner',
  /** Brand Report banner for generated */
  BrandReportGeneratedBanner = 'brandReportGeneratedBanner',
  ChangedMediaLicenseType = 'changedMediaLicenseType',
  CopilotAiWriterExplanation = 'copilotAiWriterExplanation',
  CopilotAiWriterFaq = 'copilotAiWriterFaq',
  CopilotContactCleanerExplanation = 'copilotContactCleanerExplanation',
  CopilotIdeationExplanation = 'copilotIdeationExplanation',
  CoverageReportDashboard = 'coverageReportDashboard',
  CoverageReportOnboarding = 'coverageReportOnboarding',
  CoverageReportQ1Report = 'coverageReportQ1Report',
  CoverageReportSectionsMonitor = 'coverageReportSectionsMonitor',
  FreePlanLimitation = 'freePlanLimitation',
  GuideBannerAnalyzeOverviewPage = 'guideBannerAnalyzeOverviewPage',
  GuideBannerAudienceBuilderPage = 'guideBannerAudienceBuilderPage',
  GuideBannerAudienceTargetingPage = 'guideBannerAudienceTargetingPage',
  GuideBannerContactListPage = 'guideBannerContactListPage',
  GuideBannerFindContactsPage = 'guideBannerFindContactsPage',
  GuideBannerPressreleasePage = 'guideBannerPressreleasePage',
  LostAiWriterFeature = 'lostAiWriterFeature',
  MaintenanceInfo = 'maintenanceInfo',
  MultipleBoilerplates = 'multipleBoilerplates',
  NewAudienceBuilderNewCustomerBanner = 'newAudienceBuilderNewCustomerBanner',
  NewAudienceBuilderOldCustomerBanner = 'newAudienceBuilderOldCustomerBanner',
  OnboardingStepsWizard = 'onboardingStepsWizard',
  /** Flow encouraging user to fill in newsroom information */
  OnboardingWizard = 'onboardingWizard',
  PaywallTrialExpired = 'paywallTrialExpired',
  Pitch = 'pitch',
  PlanUpsell = 'planUpsell',
  PrReport = 'prReport',
  /** @deprecated Deprecated */
  PublishSidebar = 'publishSidebar',
  PublishSidebarExplanation = 'publishSidebarExplanation',
  SocialMedia = 'socialMedia',
  TrialDemo = 'trialDemo',
  TrialOnboardingForm = 'trialOnboardingForm',
  UpdateGaId = 'updateGaId',
  /** Video & text info shown on the dashboard. */
  UserOnboarding = 'userOnboarding'
}

/** Autogenerated input type of NoticeConfirm */
export type NoticeConfirmInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notice: Notice;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of NpsAnswer */
export type NpsAnswerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  freetext?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  score: Scalars['Int']['input'];
};

export enum OnboardingStep {
  Account = 'ACCOUNT',
  Analyze = 'ANALYZE',
  Contacts = 'CONTACTS',
  Newsroom = 'NEWSROOM',
  Pressrelease = 'PRESSRELEASE',
  Preview = 'PREVIEW',
  PublishPressrelease = 'PUBLISH_PRESSRELEASE',
  VideoCourses = 'VIDEO_COURSES'
}

export enum OpenEmailsStatsDayOfTheWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

/** Autogenerated input type of PasswordChange */
export type PasswordChangeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

/** Autogenerated input type of PasswordCheck */
export type PasswordCheckInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/** Autogenerated input type of PasswordForgot */
export type PasswordForgotInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentSubscriptionCancelCancellationInfo = {
  productsToCancel?: InputMaybe<Array<PaymentSubscriptionCancelProductsToCancel>>;
  type: PaymentSubscriptionCancelType;
  userDetails?: InputMaybe<Scalars['String']['input']>;
  whatToCancel?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of PaymentSubscriptionCancel */
export type PaymentSubscriptionCancelInput = {
  cancellationInfo: PaymentSubscriptionCancelCancellationInfo;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  /** Reason to why the user decides to change plan. */
  reason?: InputMaybe<PaymentSubscriptionCancelReason>;
};

export type PaymentSubscriptionCancelProductsToCancel = {
  id: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentSubscriptionCancelReason = {
  freeText?: InputMaybe<Scalars['String']['input']>;
  mainReason: Scalars['String']['input'];
  subReason?: InputMaybe<Scalars['String']['input']>;
};

export enum PaymentSubscriptionCancelType {
  All = 'All',
  Partial = 'PARTIAL'
}

export enum PdfSlideshowUploadState {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Timeout = 'TIMEOUT'
}

/** Autogenerated input type of PitchDelete */
export type PitchDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of PitchSend */
export type PitchSendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pitchId: Scalars['ID']['input'];
};

export enum PitchState {
  Bounced = 'BOUNCED',
  Clicked = 'CLICKED',
  Draft = 'DRAFT',
  Dropped = 'DROPPED',
  Opened = 'OPENED',
  Sent = 'SENT',
  SpamReported = 'SPAM_REPORTED'
}

export enum PitchStatusFilter {
  Clicked = 'clicked',
  Draft = 'draft',
  MediaHit = 'mediaHit',
  Opened = 'opened',
  Sent = 'sent',
  Unreachable = 'unreachable'
}

export type PitchToInput = {
  mndReachJournalistId?: InputMaybe<Scalars['ID']['input']>;
  networkContactId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of PitchUpdate */
export type PitchUpdateInput = {
  attachment?: InputMaybe<MaterialIdTuple>;
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
  replyTo?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<PitchToInput>;
};

export enum PlanColor {
  Danger = 'DANGER',
  Neutral = 'NEUTRAL',
  Primary = 'PRIMARY'
}

/** Autogenerated input type of PrImpactCreateHit */
export type PrImpactCreateHitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
  publishedAt: Scalars['DateTime']['input'];
  source: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** Autogenerated input type of PressReleaseUpdate */
export type PressReleaseUpdateInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  generatedByAiWriter?: InputMaybe<Scalars['Boolean']['input']>;
  generatedByAiWriterTemplate?: InputMaybe<GeneratedByAiWriterTemplate>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageCaption?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  resourceImageId?: InputMaybe<Scalars['ID']['input']>;
  showAiDisclaimerText?: InputMaybe<Scalars['Boolean']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of PressroomGoLive */
export type PressroomGoLiveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export enum PreviewCountry {
  Dk = 'dk',
  No = 'no',
  Se = 'se',
  Uk = 'uk'
}

/** Autogenerated input type of QuotationDelete */
export type QuotationDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  quotationId: Scalars['ID']['input'];
};

export enum QuotationSettingsMatchLevel {
  High = 'high',
  Low = 'low',
  Max = 'max',
  Medium = 'medium',
  Minimum = 'minimum'
}

export enum QuotationSettingsSorting {
  MatchLevel = 'match_level',
  PublishedAt = 'published_at'
}

export enum Readability {
  Difficult = 'DIFFICULT',
  Easy = 'EASY',
  Medium = 'MEDIUM',
  VeryDifficult = 'VERY_DIFFICULT',
  VeryEasy = 'VERY_EASY'
}

export enum RecipientDomainOrUnknownSort {
  AscendingClicked = 'ASCENDING_CLICKED',
  AscendingName = 'ASCENDING_NAME',
  AscendingOpened = 'ASCENDING_OPENED',
  AscendingRecipients = 'ASCENDING_RECIPIENTS',
  AscendingUniqueOpened = 'ASCENDING_UNIQUE_OPENED',
  DescendingClicked = 'DESCENDING_CLICKED',
  DescendingName = 'DESCENDING_NAME',
  DescendingOpened = 'DESCENDING_OPENED',
  DescendingRecipients = 'DESCENDING_RECIPIENTS',
  DescendingUniqueOpened = 'DESCENDING_UNIQUE_OPENED'
}

/** Autogenerated input type of RecommendedContactsAddToMaterial */
export type RecommendedContactsAddToMaterialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contacts: Array<RecommendedContactsInput>;
  materialId: Scalars['ID']['input'];
  materialType: MaterialType;
  pressroomId: Scalars['ID']['input'];
};

export type RecommendedContactsInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum ReferrerIcon {
  DollarSign = 'DOLLAR_SIGN',
  Envelope = 'ENVELOPE',
  ExternalLink = 'EXTERNAL_LINK',
  Globe = 'GLOBE',
  Question = 'QUESTION',
  Rss = 'RSS',
  Search = 'SEARCH',
  Share = 'SHARE'
}

/** Autogenerated input type of RegenerateSearchProfile */
export type RegenerateSearchProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** does nothing unless timespan is RANGE */
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  pressroomId: Scalars['ID']['input'];
  profileId: Scalars['ID']['input'];
  /** defaults to LAST_HALF_YEAR */
  timespan?: InputMaybe<BrandReportTimespan>;
  /** does nothing unless timespan is RANGE */
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum RelativePlacement {
  After = 'AFTER',
  Before = 'BEFORE'
}

/** Autogenerated input type of RemoveHitFromClipboard */
export type RemoveHitFromClipboardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Use clipboardIds instead */
  clipboardId?: InputMaybe<Scalars['ID']['input']>;
  clipboardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Use hitIds instead */
  hitId?: InputMaybe<Scalars['ID']['input']>;
  hitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of RemoveHits */
export type RemoveHitsInput = {
  articleIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of ReportAddExternalCoverage */
export type ReportAddExternalCoverageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
  reportSectionId: Scalars['ID']['input'];
  urls: Array<Scalars['String']['input']>;
};

/** Autogenerated input type of ReportAddMonitorCoverage */
export type ReportAddMonitorCoverageInput = {
  articleIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  removedArticleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reportSectionId: Scalars['ID']['input'];
};

/** Autogenerated input type of ReportAddSection */
export type ReportAddSectionInput = {
  anchorSection?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  placement?: InputMaybe<RelativePlacement>;
  reportId: Scalars['ID']['input'];
};

export enum ReportCampaign {
  Autogenerated_2023Q1 = 'AUTOGENERATED_2023_Q1',
  Autogenerated_2024Q1 = 'AUTOGENERATED_2024_Q1',
  TryBrandReport_2024 = 'TRY_BRAND_REPORT_2024'
}

/** Autogenerated input type of ReportCancelImportJob */
export type ReportCancelImportJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['ID']['input'];
};

/** Autogenerated input type of ReportCreate */
export type ReportCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data: NewReportInputWrapper;
};

export enum ReportImportJobState {
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  Error = 'ERROR',
  Running = 'RUNNING'
}

export enum ReportLogo {
  Image = 'IMAGE',
  Text = 'TEXT'
}

export enum ReportMetric {
  Coverages = 'COVERAGES',
  Engagement = 'ENGAGEMENT',
  EstimatedReach = 'ESTIMATED_REACH',
  EstimatedSocialMediaReach = 'ESTIMATED_SOCIAL_MEDIA_REACH',
  EstimatedTotalReach = 'ESTIMATED_TOTAL_REACH',
  Mentions = 'MENTIONS',
  NetSentimentScore = 'NET_SENTIMENT_SCORE',
  PrValue = 'PR_VALUE',
  Sentiment = 'SENTIMENT',
  SentimentPercentage = 'SENTIMENT_PERCENTAGE',
  ShareOfVoice = 'SHARE_OF_VOICE'
}

/** Autogenerated input type of ReportPopulateFeatured */
export type ReportPopulateFeaturedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
};

export enum ReportPrValueCurrency {
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Nok = 'NOK',
  Sek = 'SEK',
  Usd = 'USD'
}

export enum ReportPrValuePricingModel {
  Cpc = 'CPC',
  Cpm = 'CPM'
}

/** Autogenerated input type of ReportRemoveCoverage */
export type ReportRemoveCoverageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  quotationId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

/** Autogenerated input type of ReportRemove */
export type ReportRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
};

/** Autogenerated input type of ReportRemoveSection */
export type ReportRemoveSectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reportSectionId: Scalars['ID']['input'];
};

/** Autogenerated input type of ReportRequestPdfSlideshow */
export type ReportRequestPdfSlideshowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export enum ReportSectionDesign {
  FullWidth = 'FULL_WIDTH',
  Grid = 'GRID'
}

export enum ReportSectionState {
  Aggregated = 'AGGREGATED',
  Done = 'DONE',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

/** Autogenerated input type of ReportSetCoverageFeatured */
export type ReportSetCoverageFeaturedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  featured: Scalars['Boolean']['input'];
  quotationId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

/** Autogenerated input type of ReportSetCoverageHidden */
export type ReportSetCoverageHiddenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  hidden: Scalars['Boolean']['input'];
  quotationId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

/** Autogenerated input type of ReportSetCoverageSentiment */
export type ReportSetCoverageSentimentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  quotationId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  sentiment: Sentiment;
};

/** Autogenerated input type of ReportSortCoverage */
export type ReportSortCoverageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<MoveDirection>;
  /** use direction with this argument */
  quotationId?: InputMaybe<Scalars['ID']['input']>;
  reportId: Scalars['ID']['input'];
  sortedQuotationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum ReportSortOption {
  DescendingCreatedAt = 'DESCENDING_CREATED_AT',
  DescendingUpdatedAt = 'DESCENDING_UPDATED_AT'
}

/** Autogenerated input type of ReportSortSection */
export type ReportSortSectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  direction: MoveDirection;
  reportSectionId: Scalars['ID']['input'];
};

/** Autogenerated input type of ReportUpdateCoverage */
export type ReportUpdateCoverageInput = {
  addedMaterials?: InputMaybe<Array<MaterialIdTuple>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  removedMaterials?: InputMaybe<Array<MaterialIdTuple>>;
  reportId: Scalars['ID']['input'];
  reportSectionId: Scalars['ID']['input'];
};

/** Autogenerated input type of ReportUpdate */
export type ReportUpdateInput = {
  brandColor?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the cover image. Use an empty string to clear the cover image. */
  coverImageId?: InputMaybe<Scalars['ID']['input']>;
  coverageDescription?: InputMaybe<Scalars['String']['input']>;
  coverageTitle?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  featuredDescription?: InputMaybe<Scalars['String']['input']>;
  featuredTitle?: InputMaybe<Scalars['String']['input']>;
  hiddenSentiment?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<UserLocale>;
  /** The ID of the logotype image. Use an empty string to clear the logotype. */
  logotypeId?: InputMaybe<Scalars['ID']['input']>;
  logotypeWidth?: InputMaybe<Scalars['Int']['input']>;
  metricsDescription?: InputMaybe<Scalars['String']['input']>;
  metricsTitle?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
  textLogotype?: InputMaybe<Scalars['String']['input']>;
  textLogotypeColor?: InputMaybe<Scalars['String']['input']>;
  textLogotypeFontSize?: InputMaybe<Scalars['Int']['input']>;
  textLogotypeLetterSpacing?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typeOfLogo?: InputMaybe<ReportLogo>;
  visibleMetrics?: InputMaybe<Array<ReportMetric>>;
};

/** Autogenerated input type of ReportUpdateInsight */
export type ReportUpdateInsightInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  insight?: InputMaybe<Scalars['String']['input']>;
  reportInsightId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ReportUpdatePrValueSettings */
export type ReportUpdatePrValueSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cpc?: InputMaybe<Scalars['Float']['input']>;
  cpm?: InputMaybe<Scalars['Float']['input']>;
  currency: ReportPrValueCurrency;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  facebookCpm?: InputMaybe<Scalars['Float']['input']>;
  instagramCpm?: InputMaybe<Scalars['Float']['input']>;
  pricingModel?: InputMaybe<ReportPrValuePricingModel>;
  reportId: Scalars['ID']['input'];
  twitterCpm?: InputMaybe<Scalars['Float']['input']>;
};

/** Autogenerated input type of ReportUpdateSection */
export type ReportUpdateSectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  design?: InputMaybe<ReportSectionDesign>;
  reportSectionId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ReportsGenerateSectionDescriptionPromptData */
export type ReportsGenerateSectionDescriptionPromptDataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sectionId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of RequestDemo */
export type RequestDemoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  scope: RequestDemoScopeInput;
};

export type RequestDemoScopeInput = {
  /** Only use for values which cant be represented by CommercialProductType */
  hubspotDemos?: InputMaybe<Array<HubspotDemos>>;
  products?: InputMaybe<Array<CommercialProduct>>;
};

export type ResourceImageInput = {
  cloudinaryResponse: CloudinaryResponse;
  fileName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Autogenerated input type of ResourceImageUpdate */
export type ResourceImageUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** X of the central focus point of the rectangle */
  coverImageGravityX?: InputMaybe<Scalars['Int']['input']>;
  /** Y of the central focus point of the rectangle */
  coverImageGravityY?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of ResourceImagesBulkDownload */
export type ResourceImagesBulkDownloadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  resourceImageIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated input type of SaveScheduledSendExclusive */
export type SaveScheduledSendExclusiveInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
  receivers: SendExclusiveReceivers;
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  sender: SendExclusiveSender;
  subject: Scalars['String']['input'];
};

export type ScheduleInput = {
  days: Array<Weekday>;
  times: Array<Scalars['Time']['input']>;
};

export type SearchParamsInput = {
  coverageFocuses?: InputMaybe<Array<SearchParamsLookupInput>>;
  jobRoles?: InputMaybe<Array<SearchParamsLookupInput>>;
  languageCodes?: InputMaybe<Array<SearchParamsLookupInput>>;
  locations?: InputMaybe<Array<SearchParamsLocationsInput>>;
  mediaTypes?: InputMaybe<Array<SearchParamsLookupInput>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  outlets?: InputMaybe<Array<SearchParamsLocationsInput>>;
  productTypes?: InputMaybe<Array<SearchParamsLookupInput>>;
  subject?: InputMaybe<Array<SearchParamsLookupInput>>;
  targetAudiences?: InputMaybe<Array<SearchParamsLookupInput>>;
};

export type SearchParamsLocationsInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type SearchParamsLookupInput = {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

/** Attributes representing the params for a monitor search profile */
export type SearchProfileInput = {
  /** Boolean search string. One of this or searchSpec is required */
  booleanSearch?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  /** Keyword search specification. One of this or booleanSearch is required */
  searchSpec?: InputMaybe<SearchProfileKeywordsInput>;
  sourceSelection: SourceSelection;
};

/** Represents the terms/keywords that composes a search. */
export type SearchProfileKeywordsInput = {
  allExcludes: Array<TermsSearchInput>;
  allIncludes: Array<TermsSearchInput>;
  multipleSearch: Scalars['Boolean']['input'];
  searches: Array<TermsSearchInput>;
};

/** Autogenerated input type of SendClipboardEmail */
export type SendClipboardEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clipboardEmail: ClipboardEmailInput;
  clipboardId: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

export type SendExclusiveFilter = {
  contactListId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<NetworkContactFilter>;
  searchString?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of SendExclusive */
export type SendExclusiveInput = {
  attachedItems: Array<MaterialIdTuple>;
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  receivers: SendExclusiveReceivers;
  sender: SendExclusiveSender;
  subject: Scalars['String']['input'];
};

export type SendExclusiveReceivers = {
  contactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  contactListIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sendExclusiveFilter?: InputMaybe<SendExclusiveFilter>;
};

export enum SendExclusiveSender {
  Pressroom = 'PRESSROOM',
  User = 'USER'
}

export enum Sentiment {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE'
}

export type SettingsFieldsInput = {
  sortingType?: InputMaybe<QuotationSettingsSorting>;
};

export enum SimpleSearchProfileCompetitorsState {
  Done = 'DONE',
  Error = 'ERROR',
  Pending = 'PENDING',
  Running = 'RUNNING'
}

export type SimpleSearchProfileSourceSelection = {
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  languages?: InputMaybe<Array<MonitorProfileLanguage>>;
  mediaSources?: InputMaybe<Array<MonitorMediaCategory>>;
  sourceGroupsExcluded?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum SimpleSearchProfileState {
  Done = 'DONE',
  Error = 'ERROR',
  Identified = 'IDENTIFIED',
  Pending = 'PENDING',
  Running = 'RUNNING'
}

export type SourceSelection = {
  countries: Array<Scalars['String']['input']>;
  languages: Array<MonitorProfileLanguage>;
  mediaTypes: Array<MonitorProfileMediaCategory>;
  socialMedia?: InputMaybe<Array<MonitorSocialMediaSource>>;
  sourceGroupsExcluded?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourcesExcluded?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated input type of SourceToggle2fa */
export type SourceToggle2faInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otpEnforced: Scalars['Boolean']['input'];
  sourceId: Scalars['ID']['input'];
};

export type SourceToggleFollowerNotificationTuple = {
  follow: Scalars['Boolean']['input'];
  sourceId: Scalars['ID']['input'];
};

/** Autogenerated input type of SourceToggleFollowerNotifications */
export type SourceToggleFollowerNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceInputs: Array<SourceToggleFollowerNotificationTuple>;
};

/** Autogenerated input type of StoryIdeaLike */
export type StoryIdeaLikeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  like: Scalars['Boolean']['input'];
  pressroomId: Scalars['ID']['input'];
  storyId: Scalars['ID']['input'];
};

export enum StoryWidgetLayout {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical'
}

export enum StoryWidgetStyle {
  Classic = 'CLASSIC',
  New = 'NEW'
}

/** Autogenerated input type of StoryWidgetUpdate */
export type StoryWidgetUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  layout?: InputMaybe<StoryWidgetLayout>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  pressroomId: Scalars['ID']['input'];
  style?: InputMaybe<StoryWidgetStyle>;
  tagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  types?: InputMaybe<Array<MaterialType>>;
};

export enum StyleVersion {
  Preview = 'PREVIEW',
  Production = 'PRODUCTION'
}

/** Autogenerated input type of TagCombine */
export type TagCombineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** array of tag ids to combine */
  ids: Array<Scalars['ID']['input']>;
  /** name of the new tag */
  name: Scalars['String']['input'];
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of TagDelete */
export type TagDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** array of tag ids to delete */
  ids: Array<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of TagMoveHighlighted */
export type TagMoveHighlightedInput = {
  /** move above or below `relative_to` */
  above?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** tag id to move */
  id: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
  /** relative to this tag id */
  relativeTo: Scalars['ID']['input'];
};

export enum TagSortOrder {
  Count = 'COUNT',
  Name = 'NAME'
}

/** Autogenerated input type of TagUntagMaterials */
export type TagUntagMaterialsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** tag id to untag materials from */
  id: Scalars['ID']['input'];
  materials: Array<TaggedMaterial>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of TagUpdate */
export type TagUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  highlighted?: InputMaybe<Scalars['Boolean']['input']>;
  /** tag id to update */
  id: Scalars['ID']['input'];
  imageId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

export type TaggedMaterial = {
  id: Scalars['ID']['input'];
  type: MaterialType;
};

/** Represents one terms search field. */
export type TermsSearchInput = {
  caseSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  excludes?: InputMaybe<Array<Scalars['String']['input']>>;
  field: Scalars['String']['input'];
  includes?: InputMaybe<Array<Scalars['String']['input']>>;
  index?: InputMaybe<Scalars['Int']['input']>;
  operation: Scalars['String']['input'];
  proximity?: InputMaybe<Scalars['Int']['input']>;
  tokens: Scalars['String']['input'];
};

export enum Tier {
  None = 'NONE',
  StrategicT1 = 'STRATEGIC_T1',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3'
}

/** Autogenerated input type of TryBrandReportCampaignCreateReport */
export type TryBrandReportCampaignCreateReportInput = {
  brand: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country: TryBrandReportCampaignReportCountry;
  email: Scalars['String']['input'];
  hubspotToken?: InputMaybe<Scalars['String']['input']>;
};

export enum TryBrandReportCampaignReportCountry {
  Dk = 'dk',
  No = 'no',
  Se = 'se',
  Uk = 'uk'
}

export enum TryBrandReportCampaignStatus {
  Error = 'error',
  Success = 'success'
}

/** Autogenerated input type of TwoFactorDisable */
export type TwoFactorDisableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/** Autogenerated input type of TwoFactorEnable */
export type TwoFactorEnableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otp: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated input type of TwoFactorRenewRecoveryCodes */
export type TwoFactorRenewRecoveryCodesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/** Autogenerated input type of UpdateClipboardHits */
export type UpdateClipboardHitsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clipboardId: Scalars['ID']['input'];
  hitIds: Array<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateClipboard */
export type UpdateClipboardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clipboardId: Scalars['ID']['input'];
  payload: ClipboardInput;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateEmailReportsSettings */
export type UpdateEmailReportsSettingsInput = {
  analyzeReportsSettings?: InputMaybe<EmailReportsAnalyzeReportsSettingsAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ideationEmailReportsSettings?: InputMaybe<EmailReportsIdeationEmailReportsSettingsAttributes>;
  prImpactReportsSettings?: InputMaybe<EmailReportsPrImpactReportsSettingsAttributes>;
  pressroomId: Scalars['ID']['input'];
};

export type UpdateHit2DataInput = {
  sentiment?: InputMaybe<Sentiment>;
};

/** Autogenerated input type of UpdateHit2 */
export type UpdateHit2Input = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data: UpdateHit2DataInput;
  id: Scalars['ID']['input'];
  pressroomId: Scalars['ID']['input'];
};

export type UpdateHitDataInput = {
  sentiment?: InputMaybe<Sentiment>;
};

/** Autogenerated input type of UpdateHit */
export type UpdateHitInput = {
  articleId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data: UpdateHitDataInput;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateSearchProfile */
export type UpdateSearchProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
  regenerate?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<SearchProfileInput>;
};

/** Autogenerated input type of UserAccessDelete */
export type UserAccessDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of UserActivationEmailResend */
export type UserActivationEmailResendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UserActivationEmailUpdate */
export type UserActivationEmailUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated input type of UserInvitationCreate */
export type UserInvitationCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
};

/** Autogenerated input type of UserInvitationDelete */
export type UserInvitationDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  sourceId: Scalars['ID']['input'];
};

export enum UserLocale {
  Da = 'da',
  De = 'de',
  En = 'en',
  Fi = 'fi',
  No = 'no',
  Sv = 'sv'
}

/** Autogenerated input type of UserOnboardingCompletion */
export type UserOnboardingCompletionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  step: OnboardingStep;
};

/** Autogenerated input type of UserPromoteToOwner */
export type UserPromoteToOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of UserRemoveSelf */
export type UserRemoveSelfInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type UserUpdateFields = {
  email?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<UserLocale>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UserUpdate */
export type UserUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  userFields: UserUpdateFields;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of VideoCreateScreen9UploadLink */
export type VideoCreateScreen9UploadLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of VideoProgressUpdate */
export type VideoProgressUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  progressPercentage: Scalars['Int']['input'];
};

/** Autogenerated input type of VideoRemoveResourceVideo */
export type VideoRemoveResourceVideoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
};

/** Autogenerated input type of VideoSyncScreen9Upload */
export type VideoSyncScreen9UploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  pressroomId: Scalars['ID']['input'];
  /** VideoUploadLink.id used to upload the video to screen9 */
  uploadLinkId: Scalars['ID']['input'];
  /** Updates this video if present, otherwise will create */
  videoId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of VideoUpdate */
export type VideoUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  licenseId?: InputMaybe<Scalars['ID']['input']>;
  pressroomId: Scalars['ID']['input'];
  resourceVideoId?: InputMaybe<Scalars['ID']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of VideoUpdateThumbnail */
export type VideoUpdateThumbnailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Select thumbnail from the media bank */
  resourceImageId?: InputMaybe<Scalars['ID']['input']>;
  /** Select the thumbnail from a complete url, like the premade ones from Screen9 */
  url?: InputMaybe<Scalars['String']['input']>;
  videoId: Scalars['ID']['input'];
};

export enum Visibility {
  Hidden = 'HIDDEN',
  Pinned = 'PINNED',
  Visible = 'VISIBLE'
}

export enum Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}
