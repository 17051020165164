
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { ApolloProvider } from '@apollo/client';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { UIContextProvider, UIContextValue } from '@mnd-frontend/ui';
import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';
import { AppProps } from 'next/app';
import localFont from 'next/font/local';
import { useRouter } from 'next/router';
import qs from 'qs';
import { useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { ToastContextProvider } from '../contexts/ToastContext';
import { usePageTracking } from '../hooks/usePageTracking';
import { useApollo } from '../lib/apolloClient';
import { CSS_Variables } from '../styles';
import './styles.css';
const silka = localFont({
    preload: true,
    src: [
        {
            path: './fonts/silka-regular-webfont.woff2',
            weight: 'normal',
            style: 'normal'
        },
        {
            path: './fonts/silka-bold-webfont.woff2',
            weight: 'bold',
            style: 'normal'
        },
        {
            path: './fonts/silka-medium-webfont.woff2',
            weight: '500',
            style: 'normal'
        },
        {
            path: './fonts/silka-semibold-webfont.woff2',
            weight: '600',
            style: 'normal'
        },
        {
            path: './fonts/silka-black-webfont.woff2',
            weight: '900',
            style: 'normal'
        },
        {
            path: './fonts/silka-regularitalic-webfont.woff2',
            weight: 'normal',
            style: 'italic'
        },
        {
            path: './fonts/silka-blackitalic-webfont.woff2',
            weight: '900',
            style: 'italic'
        },
    ]
});
const GlobalStyles = createGlobalStyle `
  ${CSS_Variables}
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
      color: var(--text-color,#000);
      font-family: var(--body-font-family,sans-serif);
      font-size: var(--body-font-size,1rem);
      font-style: var(--body-font-style,normal);
      font-weight: var(--body-font-weight,normal);
      letter-spacing: var(--body-letter-spacing,0);
      line-height: var(--body-line-height,1.6);
      text-transform: var(--body-text-transform,none)
  }
`;
const uiContextValue: (t: Translate) => UIContextValue = t => ({
    useLocation: () => {
        const router = useRouter();
        return {
            pathname: router.pathname,
            search: qs.stringify(router.query),
            hash: router.asPath.split('#')[1]
        };
    },
    useNavigate: () => {
        const router = useRouter();
        return (location, options) => {
            if (options?.replace) {
                router.replace(location);
            }
            else {
                router.push(location);
            }
        };
    },
    useTranslation: () => ({ t })
});
function CustomApp({ Component, pageProps }: AppProps) {
    const { t } = useTranslation();
    const apolloClient = useApollo(pageProps);
    usePageTracking();
    return (<div className={`app ${silka.className}`} style={{
            '--silka-name': silka.style.fontFamily
        } as React.CSSProperties}>
      <HoneybadgerErrorBoundary honeybadger={Honeybadger}>
        <ApolloProvider client={apolloClient}>
          <UIContextProvider value={useMemo(() => uiContextValue(t), [t])}>
            <ToastContextProvider>
              <GlobalStyles />
              <Component {...pageProps}/>
            </ToastContextProvider>
          </UIContextProvider>
        </ApolloProvider>
      </HoneybadgerErrorBoundary>
    </div>);
}
const __Next_Translate__Page__19330afb736__ = CustomApp;

    export default __appWithI18n(__Next_Translate__Page__19330afb736__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  