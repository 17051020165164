import Cookies, { CookieAttributes } from 'js-cookie';

type PressroomId = string;
type TimeFilterId = string;

type Cookie =
  | 'feature_toggles'
  | 'frontend_version'
  | 'locale'
  | 'revoked_admin_view'
  | `benchmarking_settings_${PressroomId}`
  | `TimeFilter_${TimeFilterId}_${PressroomId}`
  | `social_media_tips`
  | 'mnd_coverage_report_trial_notice'
  | 'hide_generated_brand_report'
  | 'mnd_report_trial_notice';

const getDomain = () => {
  const { hostname } = window.location;
  const hostnameParts = hostname.split('.');
  hostnameParts.shift();

  if (hostnameParts.length >= 2) {
    return hostnameParts.join('.');
  }

  return hostname;
};

export const setCookie = (cookie: Cookie, value: string | object, options?: CookieAttributes) =>
  Cookies.set(cookie, typeof value === 'object' ? JSON.stringify(value) : value, {
    domain: getDomain(),
    ...options,
  });

export const getCookie = (cookie: Cookie) => Cookies.get(cookie);

export const removeCookie = (cookie: Cookie, options?: CookieAttributes) =>
  Cookies.remove(cookie, { domain: getDomain(), ...options });
