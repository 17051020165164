import { ApolloClient, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getCache } from '@mnd-frontend/utils';
import { isEqual, merge } from 'lodash-es';
import { useMemo } from 'react';
import logger from '../utils/logger';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      logger.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  if (networkError) logger.error(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  headers: {
    'accept-language': 'en' /* TODO: should come from client */,
  },
  uri: () =>
    typeof window === 'undefined'
      ? `${process.env.PRIME_URL || 'https://www.mynewsdesk.com'}/graphql`
      : '/api/graphql',
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink, httpLink]),
    cache: getCache(),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = createApolloClient();

  if (initialState) {
    const existingCache = _apolloClient.extract();

    const data = merge(existingCache, initialState, {
      arrayMerge: (destinationArray: any, sourceArray: any) => [
        ...sourceArray,
        ...destinationArray.filter((d: any) => sourceArray.every((s: any) => !isEqual(d, s))),
      ],
    });

    _apolloClient.cache.restore(data);
  }

  return _apolloClient;
}

export function addApolloState(client: any, pageProps: any) {
  pageProps[APOLLO_STATE_PROP_NAME] = client.cache.extract();

  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
