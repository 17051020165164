import { Toast, Toaster } from '@mnd-frontend/ui';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { v1 as uuid } from 'uuid';

type Toasts = {
  showFailureToast: (message: string) => void;
};

const ToastContext = createContext<Toasts>({
  showFailureToast: () => null,
});

export const ToastContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const showFailureToast = useCallback((message: string) => {
    const id = uuid();
    setToasts(currentToasts => [
      ...currentToasts,
      {
        id,
        type: 'ERROR',
        message: message,
        onClose: () => {
          setToasts(currentToasts => currentToasts.filter(t => t.id !== id));
        },
      },
    ]);
  }, []);

  return (
    <ToastContext.Provider value={useMemo(() => ({ showFailureToast }), [showFailureToast])}>
      {children}
      <Toaster
        toasts={toasts}
        onRemove={useCallback(({ id }: Pick<Toast, 'id'>) => {
          setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id));
        }, [])}
      />
    </ToastContext.Provider>
  );
};

export const useToasts = () => useContext(ToastContext);
