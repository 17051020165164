import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { track } from '../lib/tracker';

export const usePageTracking = () => {
  const router = useRouter();

  const trackPageView = (url: string) => {
    const locale = url.split('/')[1];
    const path = url.replace(`/${locale}`, '');

    track({
      event: 'Page',
      traits: {
        path,
        applicationLanguage: locale,
      },
    });
  };

  useEffect(() => {
    trackPageView(window.location.pathname);
    router.events.on('routeChangeComplete', trackPageView);
    return () => {
      router.events.off('routeChangeComplete', trackPageView);
    };
  }, [router.events]);
};
