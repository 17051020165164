module.exports = {
  locales: ['default', 'se-sv', 'de-de', 'dk-da', 'en', 'no-no'],
  defaultLocale: 'default',
  localesToIgnore: ['default'],
  defaultNS: '*',
  pages: {
    '*': ['*'],
  },
  interpolation: {
    prefix: '%{',
    suffix: '}',
  },
  loadLocaleFrom: (lang, _ns) => import(`./public/locales/${lang}.json`).then(m => m.default),
};
