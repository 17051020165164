import { initTracking } from '@mnd-frontend/tracking';

export interface FormSubmissionEvent {
  event:
    | 'M&R Trial Signup - Form Submitted'
    | 'Product Tour Trial Signup - Form Submitted'
    | 'Trial Signup - Form Submitted';
  traits: {
    market?: string;
  };
}

export interface DemoRequestFormSubmissionEvent {
  event: 'Product Tour Demo Request - Form Submitted';
}

interface HeaderStartTrialEvent {
  event: 'BR landing page - Clicked Start trial';
  traits: {
    brand: string;
  };
}

interface FooterStartTrialEvent {
  event: 'BR Trial signup - Clicked CTA';
  traits: {
    reportId: string;
    brand: string;
  };
}

interface CreateReportEvent {
  event: 'BR landing page - Clicked Create report';
  traits: {
    brand: string;
    market: string;
  };
}

interface GetFullReportEvent {
  event: 'BR landing page - Clicked Get full report';
  traits: {
    brand: string;
    market: string;
  };
}

interface ThankYouPageStartTrialEvent {
  event: 'BR landing page - Clicked Thank you page CTA';
  traits: {
    brand: string;
  };
}

interface PageEvent {
  event: 'Page';
  traits: {
    path: string;
    applicationLanguage: string;
  };
}

interface CustomTourEvent {
  event: 'CustomTourPageView';
  traits: {
    salesRepName: string;
    prospectCompany: string;
  };
}

type TrackInput =
  | FormSubmissionEvent
  | HeaderStartTrialEvent
  | FooterStartTrialEvent
  | CreateReportEvent
  | GetFullReportEvent
  | ThankYouPageStartTrialEvent
  | PageEvent
  | DemoRequestFormSubmissionEvent
  | CustomTourEvent;

export const track = initTracking<TrackInput>({
  application: 'public',
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
});
