import { css } from 'styled-components';

export const CSS_Variables = css`
  /* Typography */
  :root {
    --body-font-size: 0.9375rem;
    --body-font-family: Silka, sans-serif;
    --body-font-weight: normal;
    --body-font-style: normal;
    --body-line-height: 1.5;
    --body-letter-spacing: -0.01em;
    --body-text-transform: none;

    --font-size-x-large: 1.5rem;
    --font-size-large: 1.0625rem;
    --font-size-small: 0.8125rem;
    --font-size-x-small: 0.6875rem;

    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 900;
  }

  /* Spacing */
  :root {
    --spacing-x-small: 0.25rem;
    --spacing-small: 0.75rem;
    --spacing-medium: 1.5rem;
    --spacing-large: 3rem;
    --spacing-x-large: 5rem;
    --spacing-2x-large: 6rem;
  }

  /* Buttons */
  :root {
    --button-padding-x: 1.1rem;
    --button-padding-y: 0.58rem;
    --button-font-size: var(--body-font-size);
  }

  /* Colors */
  :root {
    --color-primary: #ff4473;

    --color-red: #ff4473;
    --color-purple: #4e49c7;
    --color-deep-purple: #181646;
    --color-orange: #ffac70;
    --color-pink: #ffc3d2;
    --color-white: #fff;
    --color-black: #000;
    --color-gray: #8b8aa2;
    --color-lightgray: #f5f5f6;

    --heading-color: #fff;
    --link-color: #4e49c7;
    --link-color-hover: #4e49c7;

    --body-bg: white;
    --bg-color: var(--body-bg);
    --text-color: #181646;
    --button-color-hover: #fe6f93;
    --purple-button-color-hover: #7e79d5;

    --color-error: #ff4473;
    --heading-font-weight: bold;
    --h1-size: clamp(2.375rem, 7vw, 4.5rem);
    --h2-size: clamp(1.5rem, 7vw, 2.75rem);
  }

  /* Layout */
  :root {
    --site-width: min(var(--site-width-min, 92vw), var(--site-width-max, 1140px));
    --site-padding: calc((100vw - var(--site-width)) / 2);

    --grid-gap: 1.625rem;
    --grid-row-gap: 3rem;
    --section-padding: clamp(4rem, 16vw, 6rem);
    --card-padding: 3rem;
    --card-padding-small: 1.5rem;
  }

  /* Forms */
  :root {
    --form-field-bg: #e8e8ed;
    --form-field-focus-bg: #fbfbfb;
    --form-field-hover-bg: var(--form-field-bg);

    --form-field-padding-x: 0.75rem;
    --form-field-padding-y: 1rem;

    --form-field-font-size: var(--body-font-size);
    --form-field-font-weight: var(--body-font-weight);
    --form-field-letter-spacing: var(--body-letter-spacing);
    --form-field-line-height: var(--body-line-height);
    --form-field-text-color: var(--text-color);

    --form-field-border-width: 1px;
    --form-field-border-style: solid;
    --form-field-border-radius: 0;
    --form-field-border-color: #e8e8ed;
    --form-field-hover-border-color: var(--form-field-border-color);
    --form-field-focus-border-color: var(--form-field-border-color);

    --select-arrow-size: 0.5rem;
    --placeholder-color: #333;
    --placeholder-focus-color: #999;
  }

  /* Animation */
  :root {
    --anim-speed: 0.4s;
    --easing: ease;
  }
`;
